import React, { useEffect, useState } from "react";
import { useNotify } from "react-admin";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import useCalEdData from '../Utils/useCalEdData';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import useUserPreferences from '../Utils/useUserPreferences';
import { useForm } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import flatten from 'flat';
import SyncIcon from '@material-ui/icons/Sync';

const PREFS_KEY = "CAL ED";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function AutocompleteService({ loading: storageProviderLoading, isAuthenticated, getRangeContent, clearAllCache }) {
  const classes = useStyles();
  const { loading, error, data, setObj, objToRecord, setHeadersMap } = useCalEdData();
  const userPrefsResult = useUserPreferences();
  const formApi = useForm();
  const notify = useNotify();
  const [open, setOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    if (refresh) {
      console.log("refreshing data...");
      (async () => {
        await clearAllCache();
        setRefresh(false);
      })();
    }
    else if (isAuthenticated && userPrefsResult.data?.[PREFS_KEY]) {
      console.log("initializing data...");
      (async () => {
        const dbInfo = userPrefsResult.data?.[PREFS_KEY];
        const validacionCol = dbInfo?.colByName["Validación"]?.col;
        const address = `${validacionCol}${dbInfo?.headersRowIndex + 1}:${validacionCol}${dbInfo?.rowCount}`;
        let result = await getRangeContent(dbInfo?.driveId, dbInfo?.itemId, dbInfo?.sheetName, address);
        setHeadersMap(dbInfo?.columnsNames.reduce((accumulator, current, idx) => {
          return {
            ...accumulator,
            [current.trim()]: idx
          };
        }, {}));
        setObj(result);
      })();
    }
  }, [isAuthenticated, userPrefsResult.data?.[PREFS_KEY], refresh]);

  useEffect(() => {
    if (error) {
      console.log(error);
      notify('The services file could not be processed, make sure it is in the expected format', 'error');
    }
  }, [error]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOnChange = async (_, value) => {
    if (value) {
      const { name, data } = value;
      const dbInfo = userPrefsResult.data?.[PREFS_KEY];
      const row = data + 1 + dbInfo.headersRowIndex;
      const address = `${dbInfo.startRangeColumn}${row}:${dbInfo.endRangeColumn}${row}`;
      let result = await getRangeContent(dbInfo.driveId, dbInfo.itemId, dbInfo.sheetName, address);
      if (result && result.text && result.text[0]) {
        const record = objToRecord({ name, data: result.text[0] });
        const attendees = [];
        const { virtualMeet, school, ...rest } = record.value;
        const obj = flatten({
          ...rest,
          virtualMeet,
          school,
          attendees
        });

        formApi.batch(() => {
          Object.entries(obj).forEach(([key, value]) =>  formApi.change(key, value));
        });
      }
    }
  };

  const handleOnRefresh = () => setRefresh(true);

  return (
    <Box py={1}>
      <Button startIcon={<SearchIcon />} onClick={handleClickOpen}>
        Search
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="service-form-dialog-title">
        <DialogTitle id="service-form-dialog-title">Search for services</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Find in the DB (Excel file) the service you need to auto-fill the form.
            {!isAuthenticated
            ?
            <Typography
              component="p"
              color="error"
              variant="caption"
            >
              Please, authenticate with Microsoft and authorize the app.
            </Typography>
            :
            !userPrefsResult.data?.[PREFS_KEY]
            ?
            <Typography
              component="p"
              color="error"
              variant="caption"
            >
              Excel file locations are missing, please setup to select services.
            </Typography>
            :
            null}
          </DialogContentText>
          <Autocomplete
            id="select-service"
            options={data || []}
            getOptionLabel={(option) => option.name}
            style={{ width: 300 }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={(loading || refresh || storageProviderLoading || userPrefsResult.loading) ? "Loading..." : "Search..."}
                variant="filled"
              />
            )}
            loading={loading || refresh || storageProviderLoading || userPrefsResult.loading}
            disabled={loading || refresh || storageProviderLoading || !isAuthenticated || !userPrefsResult.data?.[PREFS_KEY]}
            onChange={handleOnChange}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <div className={classes.wrapper}>
            <Button
              startIcon={<SyncIcon />}
              variant="outlined"
              disabled={loading || refresh || storageProviderLoading || !isAuthenticated || !userPrefsResult.data?.[PREFS_KEY]}
              onClick={handleOnRefresh}
            >
              Refresh
            </Button>
            {(loading || refresh || storageProviderLoading || userPrefsResult.loading) && <CircularProgress size={24} className={classes.buttonProgress} />}
          </div>
          <Button
            variant="contained"
            disableElevation
            onClick={handleClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
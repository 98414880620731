import React, { useState, useEffect } from "react";
import { Cache, Auth } from 'aws-amplify';

const keyPrefix = 'usrPref';

export default function useUserPreferences(props = {}) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (!props.skip) {
      getPreferences();
    }
  }, []);

  const getPreferences = async () => {
    try {
      setLoading(true);
      const { attributes } = await Auth.currentAuthenticatedUser();
      const item = Cache.getItem(`${keyPrefix}:${attributes.sub}`);
      if (item) {
        const preferences = JSON.parse(item);
        setData(preferences);
      } else {
        setData({});
      }
    } catch(e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  const setPreferences = async (value) => {
    try {
      setLoading(true);
      const { attributes } = await Auth.currentAuthenticatedUser();
      const item = JSON.stringify(value);
      console.log(item);
      Cache.setItem(`${keyPrefix}:${attributes.sub}`, item);
      setData(value);
    } catch(e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  return {
    data,
    loading,
    error,
    setPreferences,
    getPreferences,
  };
}
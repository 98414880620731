
import React from "react";
import {
  Datagrid,
  DateField,
  List,
  TextField,
  ShowButton,
  EditButton,
} from "react-admin";

const InvitationList = (props) => {
  return (
    <List bulkActionButtons={false} {...props}>
      <Datagrid>
        <TextField
          label="ID"
          source="id"
        />
        <TextField
          label="Escuela"
          source="school.name"
        />
        <TextField
          label="Cód. Escuela"
          source="school.code"
        />
        <TextField
          label="Cód. Tema"
          source="topic.code"
          sortable={false}
        />
        <DateField
          label="Fecha Final"
          source="serviceFinalDate"
        />
        <ShowButton />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default InvitationList;

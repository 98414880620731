import { Layout as RaLayout } from 'react-admin';
import Menu from './Menu';
import AppBar from './AppBar';

const Layout = props => <RaLayout
    {...props}
    menu={Menu}
    appBar={AppBar}
/>;

export default Layout;
import parse from 'date-fns/parse';
import format from 'date-fns/format';

const ampmHoursFormat = 'h:mm aaa';
export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape
export const RANGE_ADDRESS_REGEX = /\w+!([a-zA-z]+)(\d+):([a-zA-z]+)(\d+)/; // eslint-disable-line no-useless-escape
export const PHONE_REGEX = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/; // eslint-disable-line no-useless-escape

export const parseStr = (value) => {
  if (value && typeof value !== "string") {
    return String(value);
  }
  else if (!value) {
    return;
  }
  return value;
};

export const parseTime = (value) => {
  if (value && typeof value === "string") {
    return parse(value, ampmHoursFormat, new Date());
  } else if (value && typeof value.getMonth === "function") {
    return value;
  }
  return null;
};

export const getTime = (date) => {
  return format(date, ampmHoursFormat);
}

export const getMeetingPlatforms = () => ([
  { id: "", name: "None" },
  { id: "Zoom", name: "Zoom" },
  { id: "Teams", name: "Microsoft Teams" },
  { id: "Presencial", name: "Presencial" }
]);

export const isInRealLife = (value = "") => (value && value !== "Presencial");

export const getCampaignAudiences = () => ([
  { id: 'participantes', name: 'Participantes' },
  { id: 'resources', name: 'Recursos' },
]);

export const dateFormatter = (v) => {
  // v is a `Date` object
  if (!isNaN(v) && (v instanceof Date)) {
    const pad = '00';
    const yy = v.getFullYear().toString();
    const mm = (v.getMonth() + 1).toString();
    const dd = v.getDate().toString();
    return `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`;
  } else if (typeof v === "string") {
    const vv = new Date(v);
    if (isNaN(vv)) return;
    const pad = '00';
    const yy = vv.getFullYear().toString();
    const mm = (vv.getMonth() + 1).toString();
    const dd = vv.getDate().toString();
    return `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`;
  }
};

export const dateParser = (v) => {
  if (!v) return;
  const d = parse(v, 'M/d/yyyy', new Date())
  if (isNaN(d)) return;
  return d.toISOString();
};

export const emailParser = (v) => {
  if (v && EMAIL_REGEX.test(v)) {
    return v
  }
  return;
};

export const rangeAddressParser = (v) => {
  if (v && RANGE_ADDRESS_REGEX.test(v)) {
    const m = RANGE_ADDRESS_REGEX.exec(v);
    const result = {};
    m.forEach((match, groupIndex) => result[`group_${groupIndex}`] = match);
    return result;
  }
};

export const intToChars = n => `${n >= 26 ? intToChars(Math.floor(n / 26) - 1) : ''}${'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[n % 26]}`;

import React from "react";
import {
  Datagrid,
  DateField,
  List,
  TextField,
  ShowButton,
} from "react-admin";

const FileList = (props) => {
  return (
    <List bulkActionButtons={false} {...props}>
      <Datagrid>
        <TextField
          label="ID"
          source="id"
        />
        <TextField
          label="Nombre"
          source="name"
        />
        <TextField
          label="Tipo"
          source="type"
        />
        <TextField
          label="Tamaño"
          source="size"
        />
        <DateField
          label="Modificado en"
          source="updatedAt"
        />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export default FileList;


import React, { useState, useCallback } from "react";
import {
  Create,
  TextInput,
  BooleanInput,
  DateInput,
  TabbedForm,
  FormTab,
  SimpleFormIterator,
  ArrayInput,
  SelectInput,
  FormDataConsumer,
  ReferenceInput,
  email,
  regex,
  useMutation,
  useRedirect,
  useNotify,
  useLoading,
  required,
  fetchStart,
  fetchEnd,
} from "react-admin";
import { useDispatch } from 'react-redux';
import usePinpointClient from "../Utils/usePinpointClient";
import DateTimePickerInput from "../Utils/DateTimePickerInput";
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';
import { getMeetingPlatforms, getCampaignAudiences, dateParser, dateFormatter, isInRealLife, EMAIL_REGEX, PHONE_REGEX } from '../Utils/helperFx';
import useOneDrive from '../Utils/OneDrive/useOneDrive';
import DbxAuthButton from '../Utils/Dropbox/AuthButton';
import AutocompleteService from '../Utils/AutocompleteService';
import AutocompleteAttendees from '../Utils/AutocompleteAttendees';
import TimePickerInput from '../Utils/TimePickerInput';
import { ARRAY_ERROR } from 'final-form';

const emailNames = getCampaignAudiences();
const meetingPlatforms = getMeetingPlatforms();

const INVALID_PHONE_MSG = "Phone number not valid (Ex. +1 (787) 939-1234)";
const INVALID_EMAIL_MSG = "Email address not valid";

const validateEmail = email();
const validateRequired = required();
const validateRequiredEmail = [required(), email()];
///^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
const validatePhoneNumber = regex(PHONE_REGEX, INVALID_PHONE_MSG);

const arrayErrors = (errors = []) => {
  const result = [];
  const msg = errors.reduce((message, line, i, errs) => {
    if(line.length > 0) {
      return message + line.reduce((accumulator, current, j, list) => accumulator + current + (j === list.length - 1 ? "" : ", "), `${i + 1}: `) +  (i === errs.length - 1 ? "" : " | ");
    }
    return message;
  }, "");
  if(!msg) return undefined;
  result[ARRAY_ERROR] = [ msg ];
  return result;
};

const validateForm = (values) => {
  if(!values) return null;
  const { attendees, campaigns, extraCC, assistant } = values;
  let errors = {};
  const attendeesErrors = [];
  attendees?.forEach((attendee, idx) => {
    const attendeeErrors = [];
    if (!attendee || !attendee.role) {
      attendeeErrors.push("Role is required");
    }
    if (!attendee || !attendee.name) {
      attendeeErrors.push("Name is required");
    }
    if (!attendee || !attendee.email) {
      attendeeErrors.push("Email is required");
    }
    else if(!EMAIL_REGEX.test(attendee.email)) {
      attendeeErrors.push(INVALID_EMAIL_MSG);
    }
    if(attendee?.phone && !PHONE_REGEX.test(attendee.phone)) {
      attendeeErrors.push(INVALID_PHONE_MSG);
    }
    attendeesErrors.push(attendeeErrors);
  });
  if (attendeesErrors.length > 0) {
    errors.attendees = arrayErrors(attendeesErrors);
  }

  const campaignsErrors = [];
  campaigns?.forEach((campaign, idx) => {
    const campaignErrors = [];
    if (!campaign || !campaign.templateId) {
      campaignErrors.push("Template is required");
    }
    if (!campaign || !campaign.startTime) {
      campaignErrors.push("Start Time is required");
    }
    if (!campaign || !campaign.audience) {
      campaignErrors.push("Audience is required");
    }
    campaignsErrors.push(campaignErrors);
  });
  if (campaignsErrors.length > 0) {
    errors.campaigns = arrayErrors(campaignsErrors);
  }

  const extraCCErrors = [];
  extraCC?.forEach((extra, idx) => {
    const ccErrors = [];
    if (!extra || !extra.name) {
      ccErrors.push("Name is required");
    }
    if (!extra || !extra.email) {
      ccErrors.push("Email is required");
    }
    else if(!EMAIL_REGEX.test(extra.email)) {
      ccErrors.push(INVALID_EMAIL_MSG);
    }
    extraCCErrors.push(ccErrors);
  });
  if (extraCCErrors.length > 0) {
    errors.extraCC = arrayErrors(extraCCErrors);
  }

  if (assistant) {
    if (assistant.name && !assistant.email) {
      errors.assistant = {
        email: 'Requerido'
      }
    }
    else if (!assistant.name && assistant.email) {
      errors.assistant = {
        name: 'Requerido'
      }
    }
  }
  return errors;
};

const transform = (data) => {
  const { attendees, school, resource, assistant, ...rest } = data;
  const cleanedAttendees = (attendees?.map(({ schoolCode, teamSACPE, approved, observation, projectPlan, ...attr }) => attr) || []);
  return {
    ...rest,
    attendees: cleanedAttendees,
    school: {
      ...school,
      principal: {
        ...school.principal,
        role: "Principal"
      }
    },
    resource: {
      ...resource,
      role: "Provider"
    },
    assistant: assistant ? {
      ...assistant,
      role: "Assistant"
    } : undefined,
    status: "IDLE"
  }
};

const CreateActions = ({ loading, doAuth, isAuthenticated }) => {
  return (
    <Box py={2}>
      <Toolbar disableGutters>
        <Box mx={1}>
          <DbxAuthButton
            loading={loading}
            onClick={doAuth}
            disabled={isAuthenticated}
          />
        </Box>
      </Toolbar>
    </Box>
  );
};

/*
TODO:
x A+ tab add resource person (required fields)
x A+ tab contact A+ person is required (aka coordinator)
- Resumen tab add aditional service code (not required field)
x School tab add director fields and include boolean field to flag as participant
- Participant tab add Kronos number field
*/
const InvitationCreate = (props) => {
  const { basePath } = props;
  const globalLoading = useLoading();
  const dispatch = useDispatch();
  const [mutate, { loading }] = useMutation();
  const redirectTo = useRedirect();
  const notify = useNotify();
  const { createInvitationCampaign } = usePinpointClient();
  const storageProviderState = useOneDrive();

  const save = useCallback(
    async (values, redirect) => {
      try {
        const transformedValues = transform(values);
        const { campaigns, ...invitation } = transformedValues;
        const { data: newRecord } = await mutate({
          type: 'create',
          resource: 'Invitation',
          payload: { data: invitation },
        }, { returnPromise: true, action: 'MY_CUSTOM_ACTION' });
        let err;

        dispatch(fetchStart()); // start the global loading indicator
        if (campaigns) {
          for (let index = 0; index < campaigns.length; index++) {
            const campaign = campaigns[index];
            const e = await createInvitationCampaign(newRecord, campaign);
            if (e) err = e;
          }
        }

        if (err) {
          notify("Failed to create all campaigns, please try again or contact administrator", 'error');
          dispatch(fetchEnd()); // stop the global loading indicator
        } else {
          dispatch(fetchEnd()); // stop the global loading indicator
          notify('ra.notification.created', 'info', {
            smart_count: 1,
          });
        }
        
        redirectTo(redirect, basePath, newRecord.id, newRecord);
      } catch (error) {
        console.log(error);
        notify(error.message, 'error');
      }
    },
    [mutate, notify, redirectTo, dispatch, fetchStart, fetchEnd, basePath],
  );

  return (
    <Create
      {...props}
      transform={transform}
      /*actions={(
        <CreateActions
          {...storageProviderState}
        />
      )}*/
    >
      <TabbedForm
        redirect="show"
        save={save}
        saving={globalLoading || loading}
        validate={validateForm}
      >
        <FormTab label="Resumen">
          <AutocompleteService {...storageProviderState} />
          <TextInput source="serviceNum" label="Núm. Servicio" validate={validateRequired} />
          <TextInput source="projectPlan" label="Plan de Trabajo" validate={validateRequired} />
          <TextInput source="instructions_1" label="Instrucción (Primera Línea)" />
          <TextInput source="instructions_2" label="Instrucción (Segunda Línea)" />
          <TextInput source="topic.code" label="Cód. Tema" validate={validateRequired} />
          <TextInput source="topic.name" multiline label=" Tema" validate={validateRequired} />
          <TextInput source="topic.type" label="Tipo de Participante" validate={validateRequired} />
          <TextInput source="topic.modality" label="Modalidad del Tema" validate={validateRequired} />
          <TextInput source="serviceCode" multiline label="Cód. Servicio" validate={validateRequired} />
          <TextInput source="additionalServiceCode" multiline label="Cód. Servicio Adicional" />
          <DateInput source="serviceFinalDate" label="Fecha Final del Servicio" format={dateFormatter} parse={dateParser} />
          <TimePickerInput source="startTime" label="Hora Inicio" />
          <TimePickerInput source="endTime" label="Hora Salida" />
          <DateInput source="serviceContDate" label="Fecha Cont. del Servicio" format={dateFormatter} parse={dateParser} />
          <TimePickerInput source="serviceContStartTime" label="Hora Inicio" />
          <TimePickerInput source="serviceContEndTime" label="Hora Salida" />
          <DateInput source="serviceStartDate" label="Fecha Inicio del Servicio" format={dateFormatter} parse={dateParser} />
          <TimePickerInput source="serviceStartStartTime" label="Hora Inicio" />
          <TimePickerInput source="serviceStartEndTime" label="Hora Salida" />
          <TextInput source="serviceHours" multiline label="Horas Servicio" />
          <TextInput source="numberOfParticipants" label="Núm. Participantes" />
          <TextInput source="numberOfParticipantsAdic" label="Núm. Participantes Adic." />
          <TextInput source="numberOfParticipantsAdicNoCharge" label="Núm. Participantes Adic. Sin Cargos" />
          <TextInput source="totalOfParticipants" label="Total Participantes" />
          <TextInput source="schedule" label="Agenda" />
          <TextInput source="handbook" multiline label="Manual" />
          <TextInput source="listOfParticipants" label="Lista de Participantes" />
          <TextInput source="minimumParticipants" label="Participantes Minimo" />
        </FormTab>
        <FormTab label="A+">
          <TextInput source="executive.name" label="Nombre del Ejecutivo" />
          <TextInput source="executive.phone" label="Núm. Telefónico del Ejecutivo" validate={validatePhoneNumber} />
          <TextInput source="executive.email" label="Email del Ejecutivo" validate={validateEmail} />
          <TextInput source="contact.name" label="Nombre del Contacto A+" />
          <TextInput source="contact.phone" label="Núm. Telefónico del Contacto A+" validate={validatePhoneNumber} />
          <TextInput source="contact.email" label="Email del Contacto A+" validate={validateEmail} />
          <TextInput source="resource.name" label="Nombre del Recurso" validate={validateRequired} />
          <TextInput source="resource.phone" label="Núm. Telefónico del Recurso" validate={validatePhoneNumber} />
          <TextInput source="resource.email" label="Email del Recurso" validate={validateRequiredEmail} />
          <TextInput source="assistant.name" label="Nombre del Asistente" />
          <TextInput source="assistant.phone" label="Núm. Telefónico del Asistente" validate={validatePhoneNumber} />
          <TextInput source="assistant.email" label="Email del Asistente" validate={validateEmail} />
        </FormTab>
        <FormTab label="Configuración">
          <TextInput source="subject" label="Título del Email" multiline validate={validateRequired} />
          <SelectInput
            source="virtualMeet.platform"
            choices={meetingPlatforms}
            label="Lugar de Servicio"
          />
          <FormDataConsumer>
            {({ formData: { virtualMeet }, ...rest }) => (
              <TextInput
                source="virtualMeet.title"
                disabled={!isInRealLife(virtualMeet?.platform)}
                label="Título de Reunión"
                {...rest}
              />
            )}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData: { virtualMeet }, ...rest }) => (
              <TextInput
                source="virtualMeet.username"
                disabled={!isInRealLife(virtualMeet?.platform)}
                label="Nombre de Usuario"
                {...rest}
              />
            )}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData: { virtualMeet }, ...rest }) => (
              <TextInput
                source="virtualMeet.password"
                disabled={!isInRealLife(virtualMeet?.platform)}
                label="Contraseña"
                {...rest}
              />
            )}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData: { virtualMeet }, ...rest }) => (
              <TextInput
                source="virtualMeet.url"
                disabled={!isInRealLife(virtualMeet?.platform)}
                label="Enlace Reunión Virtual"
                {...rest}
              />
            )}
          </FormDataConsumer>
          <ArrayInput source="extraCC" label="Extra CC">
            <SimpleFormIterator>
              <TextInput source="name" label="Nombre"/>
              <TextInput source="email" label="Correo electrónico" />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="Escuela">
          <TextInput source="school.code" label="Cód. Escuela" validate={validateRequired} />
          <TextInput source="school.name" label="Nombre de la Escuela" validate={validateRequired} />
          <TextInput source="school.type" label="Institución de la Escuela" validate={validateRequired} />
          <TextInput source="school.region" label="Región" validate={validateRequired} />
          <TextInput source="school.principal.name" label="Nombre del Director" />
          <TextInput source="school.principal.phone" label="Núm. Telefónico del Director" validate={validatePhoneNumber} />
          <TextInput source="school.principal.email" validate={validateEmail} label="Email del Director" />
          <TextInput source="school.additionalContact.name" label="Persona Contacto Adicional" />
          <TextInput source="school.additionalContact.phone" label="Tel. Móvil Adicional" validate={validatePhoneNumber} />
          <TextInput source="school.additionalContact.email" label="Email Adicional" validate={validateEmail} />
        </FormTab>
        <FormTab label="Participantes">
          <AutocompleteAttendees {...storageProviderState} />
          <ArrayInput source="attendees" label="">
            <SimpleFormIterator>
              <TextInput source="kronosNumber" label="Núm. Kronos" />
              <TextInput source="name" label="Nombre" />
              <TextInput source="role" label="Puesto" />
              <TextInput source="phone" label="Núm. telefónico" />
              <TextInput source="email" label="Correo electrónico" />
              <TextInput source="altEmail" label="Correo electrónico Alterno" />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="Campañas">
          <ArrayInput source="campaigns" label="">
            <SimpleFormIterator>
              <ReferenceInput
                label="Plantilla"
                source="templateId"
                reference="Template"
              >
                <SelectInput />
              </ReferenceInput>
              <SelectInput
                source="audience"
                choices={emailNames}
                label="Audiencia"
              />
              <DateTimePickerInput
                source="startTime"
                label="Enviar el"
                defaultValue={null}
              />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

export default InvitationCreate;

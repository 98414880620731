import React, { useState, useEffect } from "react";
import { useNotify } from "react-admin";
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import useUserPreferences from '../useUserPreferences';
import StorageIcon from '@material-ui/icons/Storage';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import InfoIcon from '@material-ui/icons/Info';
import { useMSAuthProvider } from '../MS/AuthProvider';
import useOneDrive from './useOneDrive';
import AuthButton from './AuthButton';
import { rangeAddressParser, intToChars } from '../helperFx';
import { blue } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

function ChooserCard({ title, loading, subtitle, isSet = false, onClick }) {
  const classes = useStyles();
  return (
    <Box maxWidth="220px" m="auto" position="relative">
      <Card>
        <CardActionArea className="dropbox-button" disabled={loading} onClick={onClick}>
          <CardContent>
            <Typography variant="h6" align="center">{title}</Typography>
            <Box position="absolute" top={0} right={0} m={1}>
              {loading
              ?
              <Tooltip title="Cargando" aria-label="loading">
                <HourglassEmptyIcon htmlColor={blue[500]} />
              </Tooltip>
              :
              isSet
              ?
              <Tooltip title="Establecido" aria-label="set">
                <CheckCircleIcon color="primary" />
              </Tooltip>
              :
              <Tooltip title="Necesita ser establecido" aria-label="not-set">
                <InfoIcon color="error" />
              </Tooltip>}
            </Box>
            <Box display="flex" justifyContent="center" py={1}>
              <Avatar className={classes.large}>
                <StorageIcon />
              </Avatar>
            </Box>
            <Typography align="center" variant="body2">{subtitle}</Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Box>
  );
}

function OneDriveChooser({ filename = "", isFileSet = false, isLoading = false, getAuthenticationToken, onSuccess }) {
  const notify = useNotify();

  const handleOpen = async () => {
    var options = {
      clientId: process.env.REACT_APP_AZURE_APP_ID,
      action: "query",
      multiSelect: false,
      viewType: "files",
      advanced: {
        redirectUri: process.env.REACT_APP_HOST_URL,
        accessToken: await getAuthenticationToken(),
        endpointHint: process.env.REACT_APP_ONEDRIVE_ENDPOINT,
      },
      success: async ({ value = [] }) => {
        console.log(value);
        const [selected] = value;
        if (selected) {
          await onSuccess({
            driveId: selected.parentReference.driveId,
            itemId: selected.id
          });
        } else {
          console.log(selected);
        }
        notify("Succesfully added selected file.", 'success');
      },
      cancel: (err) => {
        console.log(err);
        notify("File selection canceled.", 'info');
      },
      error: (err) => {
        console.log(err);
        notify("Something went wrong while selecting a file from Microsoft OneDrive, please try again later.", 'error');
      }
    };
    window.OneDrive.open(options);
  };

  return (
    <ChooserCard
      title={filename}
      loading={isLoading}
      subtitle="Select file on Microsoft OneDrive"
      isSet={isFileSet}
      onClick={handleOpen}
    />
  );
}

export default function ChooseDbFiles() {
  const userPrefsResult = useUserPreferences();
  const { isAuthenticated, loading: initilizing, silentlyGetAccessToken, login } = useMSAuthProvider();
  const { error: odError, downloadFileContent, getRangeContent } = useOneDrive();
  const [loading, setLoading] = useState(false);
  const notify = useNotify();

  useEffect(() => {
    if (odError) {
      console.log(odError);
      if (odError.debug) {
        const debug = JSON.parse(odError.debug);
        switch (debug?.errorCode) {
          case "monitor_window_timeout":
            break;
          case "popup_window_error":
            break;
          default:
            notify("Something went wrong while trying to use the MS OneDrive files, please try again later. If the problem persists, contact the administrator.", 'error');
            break;
        }
      } else {
        notify("Something went wrong while trying to use the MS OneDrive files, please try again later. If the problem persists, contact the administrator.", 'error');
      }
    }
  }, [odError])

  const handleSetItem = (projectKey, item) => {
    userPrefsResult.setPreferences({
      ...userPrefsResult.data,
      [projectKey]: item
    });
  };

  const onSuccess = async (item, sheetName, keyName) => {
    setLoading(true);
    const result = await downloadFileContent(item.driveId, item.itemId, sheetName, [ "address", "columnCount", "cellCount", "rowCount", "rowIndex" ]);
    if (!result) {
      setLoading(false);
      return
    };
    const address_m = rangeAddressParser(result.address);
    const range = await getRangeContent(item.driveId, item.itemId, sheetName, `${address_m.group_1}${address_m.group_2}:${address_m.group_3}${address_m.group_2}`);
    if (!range || !range.text || !range.text.length) {
      setLoading(false);
      return
    };
    handleSetItem(keyName, {
      ...item,
      sheetName,
      address: result.address,
      columnCount: result.columnCount,
      cellCount: result.cellCount,
      rowCount: result.rowCount,
      rowIndex: result.rowIndex,
      columnsNames: range.text[0],
      startRangeColumn: address_m.group_1,
      headersRowIndex: parseInt(address_m.group_2),
      endRangeColumn: address_m.group_3,
      colByName: range.text[0].reduce((accumulator, current, idx) => {
        return {
          ...accumulator,
          [current]: {
            idx,
            col: intToChars(idx)
          }
        };
      }, {})
    });
    setLoading(false);
  }

  if (initilizing) {
    return (
      <Container maxWidth="lg">
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Box height="100vh">
      <Box pb={5} pt={2}>
        <Container maxWidth="lg">
          <Typography variant="h4" align="center">Configure Excel file location</Typography>
        </Container>
      </Box>
      <Container maxWidth="lg">
        {isAuthenticated
        ?
        <Grid container spacing={2} justify="center" alignItems="center">
          <Grid item xs={'auto'}>
            <OneDriveChooser
              filename="Calendarización"
              isLoading={loading}
              isFileSet={userPrefsResult.data?.['CAL ED']}
              getAuthenticationToken={silentlyGetAccessToken}
              onSuccess={async item => await onSuccess(item, "CALENDARIZACION", "CAL ED")}
            />
          </Grid>
          <Grid item xs={'auto'}>
            <OneDriveChooser
              filename="Participantes"
              isLoading={loading}
              isFileSet={userPrefsResult.data?.['attendees_list']}
              getAuthenticationToken={silentlyGetAccessToken}
              onSuccess={async item => await onSuccess(item, "REGISTRO", "attendees_list")}
            />
          </Grid>
        </Grid>
        :
        <Box display="flex" flexDirection="column" alignItems="center">
            <Typography variant="h6" align="center" gutterBottom>You need to connect your Microsoft account first</Typography>
            <AuthButton onClick={login} />
        </Box>}
      </Container>
    </Box>
  )
}
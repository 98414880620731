
import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  email,
} from "react-admin";

const validateEmail = [required(), email()];

const transform = (data) => {
  return {
    ...data,
    username: data.email
  }
};

const UserCreate = (props) => {
  return (
    <Create {...props} transform={transform}>
      <SimpleForm redirect="show">
        <TextInput
          source="email"
          type="email"
          validate={validateEmail}
        />
      </SimpleForm>
    </Create>
  );
};

export default UserCreate;

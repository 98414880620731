export const config = {
  appId: process.env.REACT_APP_AZURE_APP_ID,
  redirectUri: process.env.REACT_APP_HOST_URL,
  authorityURL: `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_TENANT_NAME}`,
  scopes: [
    'user.read',
    'Files.Read.All',
    'email',
    'MailboxSettings.Read',
    'offline_access',
    'profile',
    'User.Read'
  ]
};

import React from "react";
import {
  Show,
  TextField,
  DateField,
  ArrayField,
  Datagrid,
  TabbedShowLayout,
  Tab,
} from "react-admin";

const ParticipantShow = (props) => {
  
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="Resumen">
          <TextField source="id" />
          <TextField source="kronosNumber" label="Núm. Kronos" />
          <TextField source="name" label="Nombre" />
          <TextField source="role" label="Puesto" />
          <TextField source="email" label="Email Primario" />
          <TextField source="altEmail" label="Email Alterno" />
          <TextField source="phone" label="Número Telefónico" />
          <TextField source="topic.modality" label="Modalidad" />
          <DateField source="createdAt" label="Creado en" />
          <DateField source="updatedAt" label="Modificado en" />
        </Tab>
        <Tab label="Escuela">
          <TextField source="school.code" label="Cód." />
          <TextField source="school.name" label="Nombre" />
          <TextField source="school.type" label="Tipo de Institución" />
        </Tab>
        <Tab label="Invitaciones">
          <ArrayField source="invitations" addLabel={false}>
            <Datagrid>
              <TextField source="invitation.id" label="ID" />
              <TextField source="invitation.serviceNum" label="Núm. Servicio" />
              <DateField source="invitation.serviceFinalDate" label="Fecha Final" />
              <DateField source="updatedAt" label="Modificado el" />
            </Datagrid>
          </ArrayField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default ParticipantShow;

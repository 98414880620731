
const receiversMap = {
  "participantes": 'ALL',
  "resources": 'RESOURCE'
};

export const getEmailReceivers = (type, service) => {
  let receivers;
  const { attendees, school, resource, assistant } = service;
  if (receiversMap[type] === 'ALL') {
    receivers = attendees.map(({ participant }) => participant);
    receivers.push(school.principal);
  } else if (receiversMap[type] === 'RESOURCE') {
    receivers = [ resource ];
    if (assistant?.email) {
      receivers.push(assistant);
    }
  } else {
    receivers = [];
  }
  return receivers;
};


import React from "react";
import {
  Datagrid,
  DateField,
  List,
  TextField,
  ReferenceManyField,
  SingleFieldList,
  ChipField,
  EditButton,
  BooleanField,
} from "react-admin";

const UserList = (props) => {
  return (
    <List bulkActionButtons={false} {...props}>
      <Datagrid>
        <TextField
          label="Email"
          source="email"
          sortable={false}
        />
        <BooleanField
          source="Enabled"
          sortable={false}
        />
        <TextField
          source="UserStatus"
          sortable={false}
        />
        <ReferenceManyField
          label="Groups"
          reference="cognitoGroups"
          target="listGroupsForUser.username"
          sortable={false}
        >
          <SingleFieldList linkType={false}>
            <ChipField source="id" />
          </SingleFieldList>
        </ReferenceManyField>
        <DateField
          source="UserLastModifiedDate"
          sortable={false}
        />
        <DateField
          source="UserCreateDate"
          sortable={false}
        />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default UserList;

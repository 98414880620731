/* eslint-disable */

export const listInvitations = /* GraphQL */ `
  query allInvitations($page: Int, $perPage: Int, $sortField: String, $sortOrder: String, $filter: InvitationFilter) {
    items: allInvitations(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      id
      serviceNum
      serviceCode
      serviceFinalDate
      startTime
      endTime
      virtualMeet
      school {
        id
        code
        name
        __typename
      }
      topic
      createdAt
      updatedAt
      __typename
    }
    total: _allInvitationsMeta(page: $page, perPage: $perPage, filter: $filter) {
      count
      __typename
    }
  }
`;

export const invitation = /* GraphQL */ `
  query Invitation($id: Int!) {
    data: Invitation(id: $id) {
      id
      serviceNum
      serviceCode
      additionalServiceCode
      serviceFinalDate
      startTime
      endTime
      serviceContDate
      serviceContStartTime
      serviceContEndTime
      serviceStartDate
      serviceStartStartTime
      serviceStartEndTime
      serviceHours
      numberOfParticipants
      numberOfParticipantsAdic
      numberOfParticipantsAdicNoCharge
      totalOfParticipants
      schedule
      handbook
      listOfParticipants
      minimumParticipants
      virtualMeet
      transferLink
      school {
        id
        __typename
        code
        createdAt
        name
        type
        region
        additionalContact
        principal {
          id
          __typename
          altEmail
          createdAt
          email
          name
          phone
          role
          kronosNumber
          updatedAt
        }
        updatedAt
      }
      attendees {
        participant {
          id
          __typename
          altEmail
          createdAt
          email
          name
          phone
          role
          kronosNumber
          updatedAt
        }
        createdAt
        updatedAt
        __typename
      }
      topic
      assistant {
        id
        __typename
        altEmail
        createdAt
        email
        name
        phone
        role
        kronosNumber
        updatedAt
      }
      campaigns {
        __typename
        id
        externalId
        segmentId
        template {
          __typename
          id
          name
          description
          updatedAt
          createdAt
        }
        audience
        startTime
        recipients {
          status
          statistics
          errorCode
          sysMessage
          participant {
            id
            __typename
            altEmail
            createdAt
            email
            name
            phone
            role
            kronosNumber
            updatedAt
          }
        }
        status
        statistics
        errorCode
        sysMessage
        createdAt
      }
      executive
      contact
      projectPlan
      subject
      instructions_1
      instructions_2
      extraCC
      resource {
        id
        __typename
        altEmail
        createdAt
        email
        name
        phone
        role
        kronosNumber
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

export const participant = /* GraphQL */ `
  query Participant($id: Int!) {
    data: Participant(id: $id) {
      altEmail
      email
      id
      name
      phone
      role
      kronosNumber
      school {
        id
        __typename
        code
        createdAt
        name
        type
        region
        additionalContact
        updatedAt
      }
      invitations {
        invitation {
          id
          __typename
          serviceNum
          serviceCode
          serviceFinalDate
          startTime
          endTime
          virtualMeet
          topic
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
    }
  }
`;

export const template = /* GraphQL */ `
  query Template($id: Int!) {
    data: Template(id: $id) {
      id
      name
      description
      design
      emailTemplate {
        templateName
        templateDescription
        htmlPart
        __typename
      }
      attachments {
        fileId
        file {
          id
          name
          size
          type
          key
          shareUrl
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;

import React, { useState, useEffect } from "react";
import {
  TopToolbar,
  useNotify,
  fetchStart,
  fetchEnd,
  useRefresh,
  useDataProvider,
  CreateButton
} from "react-admin";
import { CognitoUserShow } from "react-admin-amplify";
import { useDispatch } from 'react-redux';
import API from "@aws-amplify/api";
import Auth from "@aws-amplify/auth";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { useTranslate } from "react-admin";

const useStyles = makeStyles((theme) => ({
  activity: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1)
  }
}));

function useCognitoAdminAction({ path, body, record, disableSideEffect }) {
  const dispatch = useDispatch();
  const notify = useNotify();
  const refresh = useRefresh();
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if(result && result.message && !disableSideEffect) {
      notify(
        result.message.replace(record.id, record.email)
      );
      refresh();
    }
    setResult(null);
  }, [result, notify, disableSideEffect]);

  const postAction = async () => {
    try {
      setLoading(true);
      dispatch(fetchStart());
      let myInit = {
          body, 
          headers: {
            'Content-Type' : 'application/json',
            Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
          } 
      }
      const response = await API.post("AdminQueries", path, myInit);
      setResult(response);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
      dispatch(fetchEnd()); 
    }
  };

  return {
    loading,
    error,
    notify,
    refresh,
    postAction
  };
}

const EnabledButton = ({ record }) => {
  const { loading, error, notify, postAction } = useCognitoAdminAction({
    path: record.Enabled ? '/disableUser' : '/enableUser',
    body: {
      username: record.id
    },
    record
  });
  const translate = useTranslate();

  useEffect(() => {
    if (error) {
      notify('Error: Failed to complete action', 'warning')
    }
  }, [error, notify]);

  return (
    <Box mx={1}>
      <Button
        onClick={postAction}
        disabled={loading}
        variant="contained"
      >
        {record.Enabled ?  "Disable" : "Enable"}
      </Button>
    </Box>
  );
};

const ConfirmUserButton = ({ record }) => {
  const { loading, error, notify, postAction } = useCognitoAdminAction({
    path: '/confirmUserSignUp',
    body: {
      username: record.id
    },
    record
  });
  const translate = useTranslate();

  useEffect(() => {
    if (error) {
      notify('Error: Failed to complete action', 'warning')
    }
  }, [error, notify]);

  return (
    <Box mx={1}>
      <Button
        onClick={postAction}
        disabled={record.UserStatus === "CONFIRMED" || record.UserStatus === "FORCE_CHANGE_PASSWORD" || loading}
        variant="contained"
      >
        Confirm
      </Button>
    </Box>
  );
};

const AddUserToGroup = ({ record }) => {
  const [open, setOpen] = useState(false);
  const [group, setGroup] = useState(undefined);
  const [belongsTo, setBelongsTo] = useState(undefined);
  const [options, setOptions] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [fetchError, setFetchError] = useState(null);
  const dataProvider = useDataProvider();
  const classes = useStyles();
  const addUserToGroup = useCognitoAdminAction({
    path: '/addUserToGroup',
    body: {
      username: record.id,
      groupname: group
    },
    record
  });
  const removeUserFromGroup = useCognitoAdminAction({
    path: '/removeUserFromGroup',
    body: {
      username: record.id,
      groupname: belongsTo
    },
    record,
    disableSideEffect: group
  });
  const translate = useTranslate();

  useEffect(() => {
    const init = async () => {
      try {
        setFetching(true);
        const { data } = await dataProvider.getList("cognitoGroups", {
          filter: {
            listGroupsForUser: {
              username: record.id
            }
          },
          pagination: {
            page: 1,
            perPage: 25
          }
        });
        if (data && data.length > 0) {
          const [ belongs ] = data;
          setGroup(belongs.id);
          setBelongsTo(belongs.id);
        } else {
          setGroup("");
        }
        const { data: dataGroups } = await dataProvider.getList("cognitoGroups", {
          filter: {},
          pagination: {
            page: 1,
            perPage: 25
          }
        });
        if (dataGroups && dataGroups.length > 0) {
          setOptions(
            dataGroups.map(({ id }) => id)
          );
        } else {
          setOptions([]);
        }
      } catch(e) {
        setFetchError(e);
      } finally {
        setFetching(false);
      }
    };

    if (open && group === undefined) {
      init();
    }
  }, [open, group, record]);

  const handleAddToGroup = async () => {
    if (belongsTo) {
      await removeUserFromGroup.postAction();
    }
    if (group) {
      await addUserToGroup.postAction();
    }
    handleClose();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setGroup(undefined);
    setBelongsTo(undefined);
  };

  const handleChange = (event) => {
    setGroup(event.target.value);
  };

  const isLoading = () => {
    return fetching || addUserToGroup.loading || removeUserFromGroup.loading;
  };

  return (
    <div>
      <Button 
        variant="contained"
        color="primary"
        onClick={handleClickOpen}
      >
        Add To Group
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          {`Add ${record.email} to group`}
          {isLoading() && (
            <CircularProgress className={classes.activity} size={20} />
          )}
        </DialogTitle>
        <DialogContent>
          <Select
            labelId="select-helper-label"
            id="select-helper"
            value={group || ""}
            onChange={handleChange}
            disabled={isLoading()}
            fullWidth
            variant="outlined"
            margin="dense"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {options.map(opt => (
              <MenuItem key={opt} value={opt}>{opt}</MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={group === belongsTo || isLoading() || Boolean(fetchError)}
            onClick={handleAddToGroup}
            color="primary"
            variant="contained"
          >
            Add To Group
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const UserEditActions = ({ data }) => (
  <TopToolbar>
    <CreateButton basePath="/cognitoUsers" label="Create User" size="medium" />
    <ConfirmUserButton record={data} />
    <AddUserToGroup record={data} />
    <EnabledButton record={data} />
  </TopToolbar>
);

const UserTitle = ({ record }) => {
  return <span>User {record ? `"${record.email}"` : ''}</span>;
};

const UserEdit = (props) => {
  return (
    <CognitoUserShow title={<UserTitle />} actions={<UserEditActions />} {...props} />
  )
};

export default UserEdit;

import React from "react";
import {
  Edit,
  SimpleForm,
  TopToolbar,
  ShowButton,
  TextInput,
  ReferenceArrayInput,
  SelectArrayInput,
  SelectInput,
  ArrayInput,
  SimpleFormIterator,
  ReferenceInput,
} from "react-admin";
import Editor from "./Editor";

const EditActions = ({ basePath, data }) => (
  <TopToolbar>
      <ShowButton basePath={basePath} record={data} />
  </TopToolbar>
);

const TemplateEdit = (props) => {
  return (
    <Edit
      {...props}
      actions={<EditActions />}
      transform={(data) => {
        return {
          ...data,
          attachments: data.attachments?.map(({ fileId }) => ({ fileId })),
          emailTemplate: {
            templateName: data.name,
            templateDescription: data.description,
            ...data.emailTemplate
          }
        };
      }}
    >
      <SimpleForm>
        <TextInput
          source="name"
          disabled
        />
        <TextInput
          source="description"
          multiline
          resettable
        />
        <ArrayInput
          source="attachments"
          label="Attachments"
        >
          <SimpleFormIterator>
            <ReferenceInput
              source="fileId"
              reference="File"
              label="" 
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
        <Editor
          label="Contenido"
        />
      </SimpleForm>
    </Edit>
  );
};

export default TemplateEdit;
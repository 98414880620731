/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createInvitation = /* GraphQL */ `
  mutation CreateInvitation(
    $input: CreateInvitationInput!
    $condition: ModelInvitationConditionInput
  ) {
    createInvitation(input: $input, condition: $condition) {
      id
      region
      school
      service
      includeEmails
      meetingPlatform
      attendees {
        id
        name
        role
        primaryEmail
        secondaryEmail
        phone
      }
      status
      participants {
        items {
          id
          invitationID
          participantID
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateInvitation = /* GraphQL */ `
  mutation UpdateInvitation(
    $input: UpdateInvitationInput!
    $condition: ModelInvitationConditionInput
  ) {
    updateInvitation(input: $input, condition: $condition) {
      id
      region
      school
      service
      includeEmails
      meetingPlatform
      attendees {
        id
        name
        role
        primaryEmail
        secondaryEmail
        phone
      }
      status
      participants {
        items {
          id
          invitationID
          participantID
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteInvitation = /* GraphQL */ `
  mutation DeleteInvitation(
    $input: DeleteInvitationInput!
    $condition: ModelInvitationConditionInput
  ) {
    deleteInvitation(input: $input, condition: $condition) {
      id
      region
      school
      service
      includeEmails
      meetingPlatform
      attendees {
        id
        name
        role
        primaryEmail
        secondaryEmail
        phone
      }
      status
      participants {
        items {
          id
          invitationID
          participantID
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createInvitationParticipant = /* GraphQL */ `
  mutation CreateInvitationParticipant(
    $input: CreateInvitationParticipantInput!
    $condition: ModelInvitationParticipantConditionInput
  ) {
    createInvitationParticipant(input: $input, condition: $condition) {
      id
      invitationID
      participantID
      invitation {
        id
        region
        school
        service
        includeEmails
        meetingPlatform
        attendees {
          id
          name
          role
          primaryEmail
          secondaryEmail
          phone
        }
        status
        participants {
          nextToken
        }
        createdAt
        updatedAt
      }
      participant {
        id
        name
        role
        primaryEmail
        secondaryEmail
        phone
        invitations {
          nextToken
        }
        createdAt
        updatedAt
      }
      status
      createdAt
      updatedAt
    }
  }
`;
export const updateInvitationParticipant = /* GraphQL */ `
  mutation UpdateInvitationParticipant(
    $input: UpdateInvitationParticipantInput!
    $condition: ModelInvitationParticipantConditionInput
  ) {
    updateInvitationParticipant(input: $input, condition: $condition) {
      id
      invitationID
      participantID
      invitation {
        id
        region
        school
        service
        includeEmails
        meetingPlatform
        attendees {
          id
          name
          role
          primaryEmail
          secondaryEmail
          phone
        }
        status
        participants {
          nextToken
        }
        createdAt
        updatedAt
      }
      participant {
        id
        name
        role
        primaryEmail
        secondaryEmail
        phone
        invitations {
          nextToken
        }
        createdAt
        updatedAt
      }
      status
      createdAt
      updatedAt
    }
  }
`;
export const deleteInvitationParticipant = /* GraphQL */ `
  mutation DeleteInvitationParticipant(
    $input: DeleteInvitationParticipantInput!
    $condition: ModelInvitationParticipantConditionInput
  ) {
    deleteInvitationParticipant(input: $input, condition: $condition) {
      id
      invitationID
      participantID
      invitation {
        id
        region
        school
        service
        includeEmails
        meetingPlatform
        attendees {
          id
          name
          role
          primaryEmail
          secondaryEmail
          phone
        }
        status
        participants {
          nextToken
        }
        createdAt
        updatedAt
      }
      participant {
        id
        name
        role
        primaryEmail
        secondaryEmail
        phone
        invitations {
          nextToken
        }
        createdAt
        updatedAt
      }
      status
      createdAt
      updatedAt
    }
  }
`;
export const createParticipant = /* GraphQL */ `
  mutation CreateParticipant(
    $input: CreateParticipantInput!
    $condition: ModelParticipantConditionInput
  ) {
    createParticipant(input: $input, condition: $condition) {
      id
      name
      role
      primaryEmail
      secondaryEmail
      phone
      invitations {
        items {
          id
          invitationID
          participantID
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateParticipant = /* GraphQL */ `
  mutation UpdateParticipant(
    $input: UpdateParticipantInput!
    $condition: ModelParticipantConditionInput
  ) {
    updateParticipant(input: $input, condition: $condition) {
      id
      name
      role
      primaryEmail
      secondaryEmail
      phone
      invitations {
        items {
          id
          invitationID
          participantID
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteParticipant = /* GraphQL */ `
  mutation DeleteParticipant(
    $input: DeleteParticipantInput!
    $condition: ModelParticipantConditionInput
  ) {
    deleteParticipant(input: $input, condition: $condition) {
      id
      name
      role
      primaryEmail
      secondaryEmail
      phone
      invitations {
        items {
          id
          invitationID
          participantID
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

import React, { useState, useEffect } from "react";
import { useNotify } from "react-admin";
import useAttendeesData from '../Utils/useAttendeesData';
import Button from '@material-ui/core/Button';
import GroupIcon from '@material-ui/icons/Group';
import useUserPreferences from '../Utils/useUserPreferences';
import { useForm } from 'react-final-form';
import flatten from 'flat';

const PREFS_KEY = "attendees_list";

export default function AutocompleteAttendees({ loading: dbxLoading, isAuthenticated, downloadFileContent }) {
  const { loading: attendeesLoading, error: attendeesError, byId: attendeesById, setObj: attendeesSetObj, objToRecord: attendeesObjToRecord } = useAttendeesData();
  const [projectPlan, setProjectPlan] = useState(null);
  const userPrefsResult = useUserPreferences();
  const formApi = useForm();
  const notify = useNotify();

  useEffect(() => {
    if (projectPlan && isAuthenticated) {
      handleDownloadContent();
    }
  }, [isAuthenticated, projectPlan]);

  useEffect(() => {
    const subscription = formApi.subscribe(
      ({ values }) => {
        setProjectPlan(values?.projectPlan);
      },
      {
        values: true
      }
    )
    return function cleanUp() {
      subscription();
    }
  }, []);

  const handleOnChange = () => {
    try {
      const service = formApi.getState().values;
      var values = attendeesObjToRecord(attendeesById[service?.school?.code]);
      let obj;
      const { attendees = [] } = service;
      const infoPrincipal = values.find(({ role = "" }) => role.toLowerCase().includes("director"));
      if (infoPrincipal) {
        const newAttendees = values.filter(({ role = "" }) => !role.toLowerCase().includes("director"));
        obj = flatten({
          ...service,
          attendees: [
            ...newAttendees,
            ...attendees,
          ],
          school: {
            ...service.school,
            principal: {
              name: infoPrincipal.name,
              phone: infoPrincipal.phone,
              email: service.school?.principal?.email || infoPrincipal.email,
            }
          }
        });
      }
      else {
        obj = flatten({
          ...service,
          attendees: [
            ...values,
            ...attendees,
          ]
        });
      }

      formApi.batch(() => {
        Object.entries(obj).forEach(([key, value]) =>  formApi.change(key, value));
      });
    } catch (e) {
      console.log(e);
      //notify(e.message, 'error');
      notify('The action could not be completed.', 'error');
    }
  };

  const handleDownloadContent = async () => {
    const id = userPrefsResult.data?.[PREFS_KEY]?.itemId;
    if (!attendeesById && id) {
      let result = await downloadFileContent(userPrefsResult.data?.[PREFS_KEY]?.driveId, id, "REGISTRO");
      attendeesSetObj(result);
    }
  };

  if (attendeesError) {
    console.log(attendeesError);
    return (
      <h3>Oops, something went wrong....</h3>
    );
  }

  return (
    <Button
      component="span"
      onClick={handleOnChange}
      startIcon={<GroupIcon />}
      disabled={attendeesLoading || dbxLoading || !isAuthenticated || !userPrefsResult.data?.[PREFS_KEY]}
    >
      Search Participants
    </Button>
  );
}
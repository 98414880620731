import React from "react";
import Button from '@material-ui/core/Button';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#3d9ae8'
    }
  }
})

export default function AuthButton({ loading, ...props }) {
  return (
    <ThemeProvider theme={theme}>
      <Button
        {...props}
        variant="contained"
        color="primary"
      >
        {loading ? "Loading..." : "Dropbox"}
      </Button>
    </ThemeProvider>
  );
}
import React, { useState } from "react";
import {
  PinpointClient,
  UpdateEndpointsBatchCommand,
  CreateSegmentCommand,
  UpdateSegmentCommand,
  DeleteSegmentCommand,
  CreateCampaignCommand,
  UpdateCampaignCommand,
  DeleteCampaignCommand,
  GetCampaignCommand
} from "@aws-sdk/client-pinpoint";
import awsExports from "../../aws-exports";
import Auth from "@aws-amplify/auth";
import { useDataProvider } from "react-admin";
import {
  getEmailReceivers,
} from "./emailTemplateFactory";
import { compress } from "lz-string";

const ApplicationId = awsExports.aws_mobile_analytics_app_id;
const FunctionArn = process.env.REACT_APP_CAMPAIGN_FUNCTION_ARN;

export default function usePinpointClient() {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const dataProvider = useDataProvider();
  
  const upsertParticipantsEndpointsBatch = async (client, participants = []) => {
    let Items = [];
    participants.forEach(participant => {
      if (participant.email) {
        Items.push({
          Address: participant.email,
          ChannelType: "EMAIL",
          EffectiveDate: new Date().toISOString(),
          Id: `${participant.id}:${participant.email}`,
          OptOut: "NONE",
          User: {
            UserAttributes: {
              'id': [
                `${participant.id || participant.email}`,
              ],
              'name': [
                participant.name,
              ],
              'role': [
                participant.role,
              ]
            },
            UserId: `${participant.id || participant.email}`
          }
        });
      }

      if (participant.altEmail) {
        Items.push({
          Address: participant.altEmail,
          ChannelType: "EMAIL",
          EffectiveDate: new Date().toISOString(),
          Id: `${participant.id}:${participant.altEmail}`,
          OptOut: "NONE",
          User: {
            UserAttributes: {
              'id': [
                `${participant.id || participant.email}`,
              ],
              'name': [
                participant.name,
              ],
              'role': [
                participant.role,
              ]
            },
            UserId: `${participant.id || participant.email}`
          }
        });
      }
    });
    const command = new UpdateEndpointsBatchCommand({
      ApplicationId,
      EndpointBatchRequest: {
        Item: Items
      }
    });

    const { MessageBody } = await client.send(command);
    return MessageBody;
  };

  const createAudienceSegment =  async (client, name, participants = []) => {
    let Name = `Audience for ${name}`;
    if (Name.length >= 64) {
      Name = Name.slice(0, 63);
    }

    const command = new CreateSegmentCommand({
      ApplicationId,
      WriteSegmentRequest: {
        Dimensions: {
          UserAttributes: {
            'id': {
              Values: participants.map(({ id, email }) => `${id || email}`),
              AttributeType: "INCLUSIVE"
            }
          }
        },
        SegmentGroups: {
          Include: "ALL"
        },
        Name
      }
    });

    const { SegmentResponse } = await client.send(command);
    return SegmentResponse;
  };

  const updateAudienceSegment =  async (client, id, name, participants = []) => {
    let Name = `Audience for ${name}`;
    if (Name.length >= 64) {
      Name = Name.slice(0, 63);
    }

    const command = new UpdateSegmentCommand({
      ApplicationId,
      SegmentId: id,
      WriteSegmentRequest: {
        Dimensions: {
          UserAttributes: {
            'id': {
              Values: participants.map(({ id, email }) => `${id || email}`),
              AttributeType: "INCLUSIVE"
            }
          }
        },
        SegmentGroups: {
          Include: "ALL"
        },
        Name
      }
    });

    const { SegmentResponse } = await client.send(command);
    return SegmentResponse;
  };

  const deleteAudienceSegment =  async (client, id) => {
    const command = new DeleteSegmentCommand({
      ApplicationId,
      SegmentId: id
    });

    const { SegmentResponse } = await client.send(command);
    return SegmentResponse;
  };

  const createCampaign =  async (client, name, segmentId, startTime, message) => {
    let Name = `Campaign for ${name}`;
    if (Name.length >= 64) {
      Name = Name.slice(0, 63);
    }

    const command = new CreateCampaignCommand({
      ApplicationId,
      WriteCampaignRequest: {
        Name,
        SegmentId: segmentId,
        CustomDeliveryConfiguration: {
          DeliveryUri: FunctionArn,
          EndpointTypes: [
            'EMAIL'
          ]
        },
        MessageConfiguration: {
          CustomMessage: {
            Data: message
          }
        },
        Schedule: {
          StartTime: startTime,
          Frequency: 'ONCE',
          Timezone: 'UTC-04'
        }
      }
    });

    const { CampaignResponse } = await client.send(command);
    return CampaignResponse;
  };

  const updateCampaign =  async (client, id, name, segmentId, startTime, message) => {
    let Name = `Campaign for ${name}`;
    if (Name.length >= 64) {
      Name = Name.slice(0, 63);
    }

    const command = new UpdateCampaignCommand({
      ApplicationId,
      CampaignId: id,
      WriteCampaignRequest: {
        Name,
        SegmentId: segmentId,
        CustomDeliveryConfiguration: {
          DeliveryUri: FunctionArn,
          EndpointTypes: [
            'EMAIL'
          ]
        },
        MessageConfiguration: {
          CustomMessage: {
            Data: message
          }
        },
        Schedule: {
          StartTime: startTime,
          Frequency: 'ONCE',
          Timezone: 'UTC-04'
        }
      }
    });

    const { CampaignResponse } = await client.send(command);
    return CampaignResponse;
  };

  const deleteCampaign =  async (client, id) => {
    const command = new DeleteCampaignCommand({
      ApplicationId,
      CampaignId: id
    });

    const { CampaignResponse } = await client.send(command);
    return CampaignResponse;
  };

  const removeTimestamps = (record) => {
    const {createdAt, updatedAt, ...cleanedRecord} = record;
    return cleanedRecord;
  };

  const updateInvitation = async (invitation = {}, campaign, recipients = [], segmentId, startTime) => {
    const { Id, remove, templateId, audience } = campaign;
    const result = await dataProvider.update('Invitation', {
      id: invitation.id,
      data: {
        id: invitation.id,
        serviceNum: invitation.serviceNum,
        school: {
          id: invitation.school.id,
          code: invitation.school.code,
          name: invitation.school.name,
          type: invitation.school.type,
          region: invitation.school.region,
          principal: removeTimestamps(invitation.school.principal),
        },
        campaigns: [
          {
            externalId: Id,
            segmentId,
            templateId,
            audience,
            remove: remove || false,
            recipients: recipients.map(({ id }) => id),
            startTime
          }
        ],
        resource: removeTimestamps(invitation.resource)
      }
    },
    { action: 'MY_CUSTOM_ACTION' });
    return result;
  };

  const toCompressedJson = (data = {}) => {
    const payload = compress(JSON.stringify(data));
    return payload;
  };

  const createInvitationCampaign = async (invitation = {}, campaign = {}) => {
    const { serviceNum, subject } = invitation;
    const { startTime, templateId, audience } = campaign;
    let templateName;
    let templateAttachments;
    let credentials;
    let instance;
    let segmentResponse;
    let participants;
    let err;
    setLoading(true);

    // Create endpoints and audience segment
    try {
      const { data } = await dataProvider.getOne('Template', { id: templateId });
      templateName = data.name;
      templateAttachments = data.attachments?.map(({ file }) => file);
      credentials = await Auth.currentCredentials();
      instance = new PinpointClient({
        region: awsExports.aws_mobile_analytics_app_region,
        credentials: Auth.essentialCredentials(credentials)
      });
      participants = getEmailReceivers(audience, invitation);
      await upsertParticipantsEndpointsBatch(instance, participants);
      segmentResponse = await createAudienceSegment(instance, serviceNum, participants);
    } catch (e) {
      console.log(e);
      err = e;
    }

    // No need to revert created resources after step failure
    if (err) {
      setError(err);
      setLoading(false);
      return err;
    }

    let campaignResponse;
    // Create email campaign
    try {
      const payload = JSON.stringify({
        body: toCompressedJson({
          templateName,
          templateAttachments,
          invitation
        }),
        subject
      });
      campaignResponse = await createCampaign(instance, serviceNum, segmentResponse.Id, startTime, payload);
    } catch (e) {
      console.log(e);
      err = e;
    }

    // Revert created resources after step failure
    if (err) {
      try {
        await deleteAudienceSegment(instance, segmentResponse.Id);
      } catch (e) {
        console.log(e);
      } finally {
        setError(err);
        setLoading(false);
      }
      return err;
    }

    // Update invitation to create campaign relation
    try {
      await updateInvitation(invitation, { ...campaignResponse, templateId, audience }, participants, segmentResponse.Id, startTime);
    } catch (e) {
      console.log(e);
      err = e;
    }

    // Revert created resources after step failure
    if (err) {
      try {
        await deleteAudienceSegment(instance, segmentResponse.Id);
        await deleteCampaign(instance, campaignResponse.Id)
      } catch (e) {
        console.log(e);
      } finally {
        setError(err);
        setLoading(false);
      }
      return err;
    }

    setLoading(false);
  };

  const updateInvitationCampaign = async (invitation = {}, campaign = {}) => {
    const { serviceNum, subject } = invitation;
    const { startTime, templateId, audience, segmentId, externalId } = campaign;
    let templateName;
    let templateAttachments;
    let credentials;
    let instance;
    let segmentResponse;
    let participants;
    let err;
    setLoading(true);

    // Update endpoints and audience segment
    try {
      const { data } = await dataProvider.getOne('Template', { id: templateId });
      templateName = data.name;
      templateAttachments = data.attachments?.map(({ file }) => file);
      credentials = await Auth.currentCredentials();
      instance = new PinpointClient({
        region: awsExports.aws_mobile_analytics_app_region,
        credentials: Auth.essentialCredentials(credentials)
      });
      participants = getEmailReceivers(audience, invitation);
      await upsertParticipantsEndpointsBatch(instance, participants);
      segmentResponse = await updateAudienceSegment(instance, segmentId, serviceNum, participants);
    } catch (e) {
      console.log(e);
      err = e;
    }

    // No need to revert updated resources after step failure
    if (err) {
      setError(err);
      setLoading(false);
      return err;
    }

    let campaignResponse;
    // Update email campaign
    try {
      const payload = JSON.parse({
        body: toCompressedJson({
          templateName,
          templateAttachments,
          invitation
        }),
        subject
      });
      campaignResponse = await updateCampaign(instance, externalId, serviceNum, segmentResponse.Id, startTime, payload);
    } catch (e) {
      console.log(e);
      err = e;
    }

    // No need to revert updated resources after step failure
    if (err) {
      setError(err);
      setLoading(false);
      return err;
    }

    // Update invitation to update related campaign
    try {
      await updateInvitation(invitation, { ...campaignResponse, templateId, audience }, participants, segmentResponse.Id, startTime);
    } catch (e) {
      console.log(e);
      err = e;
    }

    // No need to revert updated resources after step failure
    if (err) {
      setError(err);
      setLoading(false);
      return err;
    }

    setLoading(false);
  };

  const deleteInvitationCampaign = async (invitation = {}, campaign = {}) => {
    const { templateId, audience, segmentId, externalId, startTime } = campaign;
    let credentials;
    let instance;
    let err;
    setLoading(true);

    // Delete audience segment
    try {
      credentials = await Auth.currentCredentials();
      instance = new PinpointClient({
        region: awsExports.aws_mobile_analytics_app_region,
        credentials: Auth.essentialCredentials(credentials)
      });
      await deleteAudienceSegment(instance, segmentId);
    } catch (e) {
      console.log(e);
      err = e;
    }

    // No need to revert resources after step failure
    if (err) {
      setError(err);
      setLoading(false);
      return err;
    }

    let campaignResponse;
    // Delete email campaign
    try {
      campaignResponse = await deleteCampaign(instance, externalId);
    } catch (e) {
      console.log(e);
      err = e;
    }

    // No need to revert resources after step failure
    if (err) {
      setError(err);
      setLoading(false);
      return err;
    }

    // Delete invitation to delete related campaign
    try {
      await updateInvitation(invitation, { ...campaignResponse, templateId, audience, remove: true }, [], segmentId, startTime);
    } catch (e) {
      console.log(e);
      err = e;
    }

    // No need to revert resources after step failure
    if (err) {
      setError(err);
      setLoading(false);
      return err;
    }

    setLoading(false);
  };

  const areCampaignsRemovable = async (invitation = {}) => {
    let result = false;
    try {
      const credentials = await Auth.currentCredentials();
      const instance = new PinpointClient({
        region: awsExports.aws_mobile_analytics_app_region,
        credentials: Auth.essentialCredentials(credentials)
      });
      const { campaigns } = invitation;
      if (campaigns && campaigns.length > 0) {
        setFetching(true);
        const { externalId } = campaigns[0];
        const command = new GetCampaignCommand({
          ApplicationId,
          CampaignId: externalId
        });

        const { CampaignResponse } = await instance.send(command);
        result = (CampaignResponse.State.CampaignStatus === "SCHEDULED" && CampaignResponse.Schedule.StartTime !== "IMMEDIATE");
      }
    } catch (e) {
      console.log(e);
    } finally {
      setFetching(false);
    }
    return result;
  };

  const getCampaignById = async (id) => {
    let result = null;
    try {
      const credentials = await Auth.currentCredentials();
      const instance = new PinpointClient({
        region: awsExports.aws_mobile_analytics_app_region,
        credentials: Auth.essentialCredentials(credentials)
      });
      setLoading(true);
      const command = new GetCampaignCommand({
        ApplicationId,
        CampaignId: id
      });
      const { CampaignResponse } = await instance.send(command);
      result = {
        Id: CampaignResponse.Id,
        startTime: CampaignResponse.Schedule?.StartTime,
        status: CampaignResponse.State?.CampaignStatus,
        createdAt: CampaignResponse.CreationDate,
        segmentId: CampaignResponse.SegmentId
      };
    } catch(e) {
      console.log(e);
      setError(e);
    } finally {
      setLoading(false);
    }
    return result;
  };

  return {
    error,
    loading,
    fetching,
    createInvitationCampaign,
    updateInvitationCampaign,
    deleteInvitationCampaign,
    areCampaignsRemovable,
    getCampaignById,
  }
}
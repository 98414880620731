
import React, { useCallback } from "react";
import {
  Edit,
  TextInput,
  BooleanInput,
  DateInput,
  TabbedForm,
  FormTab,
  SimpleFormIterator,
  ArrayInput,
  SelectInput,
  ReferenceInput,
  FormDataConsumer,
  email,
  regex,
  required,
  useMutation,
  useRedirect,
  useNotify,
  useLoading,
  Button,
  TopToolbar,
  Toolbar,
  ShowButton,
  SaveButton,
  useDelete,
  fetchStart,
  fetchEnd,
} from "react-admin";
import { useDispatch } from 'react-redux';
import usePinpointClient from "../Utils/usePinpointClient";
import DateTimePickerInput from "../Utils/DateTimePickerInput";
import { getMeetingPlatforms, getCampaignAudiences, isInRealLife, EMAIL_REGEX, PHONE_REGEX } from '../Utils/helperFx';
import TimePickerInput from '../Utils/TimePickerInput';
import Box from '@material-ui/core/Box';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import DeleteIcon from '@material-ui/icons/Delete';
import flatten from 'flat';
import { ARRAY_ERROR } from 'final-form';

const emailNames = getCampaignAudiences();
const meetingPlatforms = getMeetingPlatforms();

const INVALID_PHONE_MSG = "Phone number not valid (Ex. +1 (787) 939-1234)";
const INVALID_EMAIL_MSG = "Email address not valid";

const validateEmail = email();
const validateRequired = required();
const validateRequiredEmail = [required(), email()];
const validatePhoneNumber = regex(PHONE_REGEX, INVALID_PHONE_MSG);

const arrayErrors = (errors = []) => {
  const result = [];
  const msg = errors.reduce((message, line, i, errs) => {
    if(line.length > 0) {
      return message + line.reduce((accumulator, current, j, list) => accumulator + current + (j === list.length - 1 ? "" : ", "), `${i + 1}: `) +  (i === errs.length - 1 ? "" : " | ");
    }
    return message;
  }, "");
  if(!msg) return undefined;
  result[ARRAY_ERROR] = [ msg ];
  return result;
};

const validateForm = (values) => {
  if(!values) return null;
  const { attendees, campaigns, extraCC, assistant } = values;
  let errors = {};
  const attendeesErrors = [];
  attendees?.forEach((record, idx) => {
    const { participant: attendee } = (record || {});
    const attendeeErrors = [];
    if (!attendee || !attendee.role) {
      attendeeErrors.push("Role is required");
    }
    if (!attendee || !attendee.name) {
      attendeeErrors.push("Name is required");
    }
    if (!attendee || !attendee.email) {
      attendeeErrors.push("Email is required");
    }
    else if(!EMAIL_REGEX.test(attendee.email)) {
      attendeeErrors.push(INVALID_EMAIL_MSG);
    }
    if(attendee?.phone && !PHONE_REGEX.test(attendee.phone)) {
      attendeeErrors.push(INVALID_PHONE_MSG);
    }
    attendeesErrors.push(attendeeErrors);
  });
  if (attendeesErrors.length > 0) {
    errors.attendees = arrayErrors(attendeesErrors);
  }

  const campaignsErrors = [];
  campaigns?.forEach((campaign, idx) => {
    const campaignErrors = [];
    if (!campaign || !campaign.template?.id) {
      campaignErrors.push("Template is required");
    }
    if (!campaign || !campaign.startTime) {
      campaignErrors.push("Start Time is required");
    }
    if (!campaign || !campaign.audience) {
      campaignErrors.push("Audience is required");
    }
    campaignsErrors.push(campaignErrors);
  });
  if (campaignsErrors.length > 0) {
    errors.campaigns = arrayErrors(campaignsErrors);
  }

  const extraCCErrors = [];
  extraCC?.forEach((extra, idx) => {
    const ccErrors = [];
    if (!extra || !extra.name) {
      ccErrors.push("Name is required");
    }
    if (!extra || !extra.email) {
      ccErrors.push("Email is required");
    }
    else if(!EMAIL_REGEX.test(extra.email)) {
      ccErrors.push(INVALID_EMAIL_MSG);
    }
    extraCCErrors.push(ccErrors);
  });
  if (extraCCErrors.length > 0) {
    errors.extraCC = arrayErrors(extraCCErrors);
  }

  if (assistant) {
    if (assistant.name && !assistant.email) {
      errors.assistant = {
        email: 'Requerido'
      }
    }
    else if (!assistant.name && assistant.email) {
      errors.assistant = {
        name: 'Requerido'
      }
    }
  }
  return errors;
};

const removeTimestamps = (record) => {
  const {createdAt, updatedAt, ...cleanedRecord} = record;
  return cleanedRecord;
};

const transform = (data) => {
  const { attendees, school, campaigns, resource, assistant, ...rest } = data;
  const cleanedAttendees = attendees?.map(({ participant, remove = false }) => {
    return {
      invitationId: rest.id,
      participant: removeTimestamps(participant),
      remove
    }
  });
  const cleanedCampaigns = campaigns?.map(({ createdAt: caa, updatedAt: uaa, template, ...restCampaign }) => {
    return {
      templateId: template.id,
      ...restCampaign
    }
  });
  const { "principal.id": x, ...cleanedSchool } = removeTimestamps(school);

  return {
    ...rest,
    attendees: cleanedAttendees,
    school: {
      ...cleanedSchool,
      principal: removeTimestamps(cleanedSchool.principal)
    },
    resource: removeTimestamps(resource),
    assistant: assistant ? {
      ...removeTimestamps(assistant),
      role: "Assistant"
    } : undefined,
    campaigns: cleanedCampaigns,
  }
};

const deleteBtnTheme = createMuiTheme({
  palette: {
    primary: red
  }
});

const DeleteButton = (props) => {
  const { resource, data, basePath } = props;
  const [deleteOne, { loading }] = useDelete(resource, data?.id, data);
  const redirectTo = useRedirect();
  const notify = useNotify();
  const { deleteInvitationCampaign } = usePinpointClient();

  const handleDelete = async () => {
    try {
      const transformedValues = transform(data);
      const { campaigns, ...invitation } = transformedValues;
      let err;

      const filtered = campaigns.filter(({ status }) => status !== "COMPLETED")
      if (filtered) {
        for (let index = 0; index < filtered.length; index++) {
          const campaign = filtered[index];
          const e = await deleteInvitationCampaign(invitation, campaign);
          if (e) err = e;
        }
      }

      if (err) {
        notify('custom.notification.campaignDeleteError', 'error');
      } else {
        await deleteOne();
        notify('ra.notification.deleted', 'info', {
          smart_count: 1,
        }, false);
      }
      redirectTo('list', basePath);
      console.log("deleted...");
    } catch (e) {
      notify(e.message, 'error');
    }
  };

  return (
    <ThemeProvider theme={deleteBtnTheme}>
      <Button
        disabled={loading}
        variant="text"
        onClick={handleDelete}
        label="Eliminar"
      >
        <DeleteIcon />
      </Button>
    </ThemeProvider>
  );
};

const EditActions = (props) => {
  const { basePath, data, resource } = props;

  return (
    <TopToolbar>
      <Box mr={1}>
        <DeleteButton {...props} />
      </Box>
      <Box ml={1}>
        <ShowButton
          basePath={basePath}
          record={data}
          resource={resource}
        />
      </Box>
    </TopToolbar>
  );
};

const FormToolbar = (props) => {
  return (
    <Toolbar>
      <SaveButton {...props} />
    </Toolbar>
  )
}

const InvitationEdit = (props) => {
  const { basePath } = props;
  const globalLoading = useLoading();
  const dispatch = useDispatch();
  const [mutate, { loading }] = useMutation();
  const redirectTo = useRedirect();
  const notify = useNotify();
  const { createInvitationCampaign, updateInvitationCampaign, deleteInvitationCampaign } = usePinpointClient();

  const save = useCallback(
    async (values, redirect) => {
      try {
        const transformedValues = transform(values);
        const { campaigns, ...invitation } = transformedValues;
        const { data: newRecord } = await mutate({
          type: 'update',
          resource: 'Invitation',
          payload: { data: invitation },
        }, { returnPromise: true, action: 'MY_CUSTOM_ACTION' });
        let err;

        dispatch(fetchStart()); // start the global loading indicator
        const filtered = campaigns.filter(({ status }) => (status !== "COMPLETED" && status !== "SUCCESS"))
        if (filtered) {
          for (let index = 0; index < filtered.length; index++) {
            const campaign = filtered[index];
            let e;
            if (!campaign.externalId) {
              e = await createInvitationCampaign(newRecord, campaign);
            } else if (!campaign.remove) {
              e = await updateInvitationCampaign(newRecord, campaign);
            } else {
              e = await deleteInvitationCampaign(newRecord, campaign);
            }
            if (e) err = e;
          }
        }

        if (err) {
          notify('Failed to update all campaigns, please try again or contact administrator', 'error');
          dispatch(fetchEnd()); // stop the global loading indicator
        } else {
          dispatch(fetchEnd()); // stop the global loading indicator
          notify('ra.notification.updated', 'info', {
            smart_count: 1,
          }, false);
        }

        redirectTo(redirect, basePath, newRecord.id, newRecord);
      } catch (error) {
        console.log(error);
        notify(error.message, 'error');
      }
    },
    [mutate, notify, redirectTo, dispatch, fetchStart, fetchEnd, basePath],
  );

  return (
    <Edit
      {...props}
      actions={<EditActions />}
      mutationMode="optimistic"
    >
      <TabbedForm
        save={save}
        saving={globalLoading || loading}
        toolbar={<FormToolbar />}
        validate={validateForm}
      >
        <FormTab label="Resumen">
          <TextInput source="serviceNum" label="Núm. Servicio" validate={validateRequired} />
          <TextInput source="projectPlan" label="Plan de Trabajo" validate={validateRequired} />
          <TextInput source="instructions_1" label="Instrucción (Primera Línea)" />
          <TextInput source="instructions_2" label="Instrucción (Segunda Línea)" />
          <TextInput source="topic.code" label="Cód. Tema" validate={validateRequired} />
          <TextInput source="topic.name" multiline label=" Tema" validate={validateRequired} />
          <TextInput source="topic.type" label="Tipo de Participante" validate={validateRequired} />
          <TextInput source="topic.modality" label="Modalidad del Tema" validate={validateRequired} />
          <TextInput source="serviceCode" multiline label="Cód. Servicio" validate={validateRequired} />
          <TextInput source="additionalServiceCode" multiline label="Cód. Servicio Adicional" />
          <DateInput source="serviceFinalDate" label="Fecha Final del Servicio" />
          <TimePickerInput source="startTime" label="Hora Inicio" />
          <TimePickerInput source="endTime" label="Hora Salida" />
          <DateInput source="serviceContDate" label="Fecha Cont. del Servicio" />
          <TimePickerInput source="serviceContStartTime" label="Hora Inicio" />
          <TimePickerInput source="serviceContEndTime" label="Hora Salida" />
          <DateInput source="serviceStartDate" label="Fecha Inicio del Servicio" />
          <TimePickerInput source="serviceStartStartTime" label="Hora Inicio" />
          <TimePickerInput source="serviceStartEndTime" label="Hora Salida" />
          <TextInput source="serviceHours" multiline label="Horas Servicio" />
          <TextInput source="numberOfParticipants" label="Núm. Participantes" />
          <TextInput source="numberOfParticipantsAdic" label="Núm. Participantes Adic." />
          <TextInput source="numberOfParticipantsAdicNoCharge" label="Núm. Participantes Adic. Sin Cargos" />
          <TextInput source="totalOfParticipants" label="Total Participantes" />
          <TextInput source="schedule" label="Agenda" />
          <TextInput source="handbook" multiline label="Manual" />
          <TextInput source="listOfParticipants" label="Lista de Participantes" />
          <TextInput source="minimumParticipants" label="Participantes Minimo" />
        </FormTab>
        <FormTab label="A+">
          <TextInput source="executive.name" label="Nombre del Ejecutivo" />
          <TextInput source="executive.phone" label="Núm. Telefónico del Ejecutivo" validate={validatePhoneNumber} />
          <TextInput source="executive.email" label="Email del Ejecutivo" validate={validateEmail} />
          <TextInput source="contact.name" label="Nombre del Contacto A+" />
          <TextInput source="contact.phone" label="Núm. Telefónico del Contacto A+" validate={validatePhoneNumber} />
          <TextInput source="contact.email" label="Email del Contacto A+" validate={validateEmail} />
          <TextInput source="resource.name" label="Nombre del Recurso" validate={validateRequired} />
          <TextInput source="resource.phone" label="Núm. Telefónico del Recurso" validate={validatePhoneNumber} />
          <TextInput source="resource.email" label="Email del Recurso" validate={validateRequiredEmail} />
          <TextInput source="assistant.name" label="Nombre del Asistente" />
          <TextInput source="assistant.phone" label="Núm. Telefónico del Asistente" validate={validatePhoneNumber} />
          <TextInput source="assistant.email" label="Email del Asistente" validate={validateEmail} />
        </FormTab>
        <FormTab label="Configuración">
          <TextInput source="subject" label="Título del Email" multiline validate={validateRequired} />
          <SelectInput
            source="virtualMeet.platform"
            choices={meetingPlatforms}
            label="Lugar de Servicio"
          />
          <FormDataConsumer>
            {({ formData: { virtualMeet }, ...rest }) => (
              <TextInput
                source="virtualMeet.title"
                disabled={!isInRealLife(virtualMeet?.platform)}
                label="Título de Reunión"
                {...rest}
              />
            )}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData: { virtualMeet }, ...rest }) => (
              <TextInput
                source="virtualMeet.username"
                disabled={!isInRealLife(virtualMeet?.platform)}
                label="Nombre de Usuario"
                {...rest}
              />
            )}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData: { virtualMeet }, ...rest }) => (
              <TextInput
                source="virtualMeet.password"
                disabled={!isInRealLife(virtualMeet?.platform)}
                label="Contraseña"
                {...rest}
              />
            )}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData: { virtualMeet }, ...rest }) => (
              <TextInput
                source="virtualMeet.url"
                disabled={!isInRealLife(virtualMeet?.platform)}
                label="Enlace Reunión Virtual"
                {...rest}
              />
            )}
          </FormDataConsumer>
          <ArrayInput source="extraCC" label="Extra CC">
            <SimpleFormIterator>
              <TextInput source="name" label="Nombre"/>
              <TextInput source="email" label="Correo electrónico" />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="Escuela">
          <TextInput source="school.code" label="Cód. Escuela" validate={validateRequired} />
          <TextInput source="school.name" label="Nombre de la Escuela" validate={validateRequired} />
          <TextInput source="school.type" label="Institución de la Escuela" validate={validateRequired} />
          <TextInput source="school.region" label="Región" validate={validateRequired} />
          <TextInput source="school.principal.name" label="Nombre del Director" />
          <TextInput source="school.principal.phone" label="Núm. Telefónico del Director" validate={validatePhoneNumber} />
          <TextInput source="school.principal.email" validate={validateEmail} label="Email del Director" />
          <TextInput source="school.additionalContact.name" label="Persona Contacto Adicional" />
          <TextInput source="school.additionalContact.phone" label="Tel. Móvil Adicional" validate={validatePhoneNumber} />
          <TextInput source="school.additionalContact.email" label="Email Adicional" validate={validateEmail} />
        </FormTab>
        <FormTab label="Participantes">
          <ArrayInput source="attendees" label="">
            <SimpleFormIterator disableRemove={({ participant }) => participant && participant.id}>
              <TextInput source="participant.kronosNumber" label="Núm. Kronos" />
              <TextInput source="participant.name" label="Nombre" />
              <TextInput source="participant.role" label="Puesto" />
              <TextInput source="participant.phone" label="Núm. telefónico" />
              <TextInput source="participant.email" label="Correo electrónico" />
              <TextInput source="participant.altEmail" label="Correo electrónico Alterno" />
              <FormDataConsumer>
                {({ formData, scopedFormData, getSource, ...rest }) => {
                  if (!scopedFormData?.participant || !scopedFormData?.participant?.id) return null;

                  return (
                    <BooleanInput {...rest} source={getSource("remove")} label="Baja" defaultValue={false} />
                  );
                }}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="Campañas">
          <ArrayInput source="campaigns" label="">
            <SimpleFormIterator disableRemove={({ id }) => Boolean(id)}>
              <TextInput source="status" label="Status" disabled />
              <FormDataConsumer>
                {({ formData, scopedFormData, getSource, ...rest }) => {
                  return (
                    <ReferenceInput
                      {...rest}
                      label="Plantilla"
                      source={getSource('template.id')}
                      reference="Template"
                    >
                      <SelectInput
                        disabled={scopedFormData?.status === "COMPLETED" || scopedFormData?.status === "SUCCESS"}
                      />
                    </ReferenceInput>
                  );
                }}
              </FormDataConsumer>
              <FormDataConsumer>
                {({ formData, scopedFormData, getSource, ...rest }) => {
                  return (
                    <SelectInput
                      {...rest}
                      source={getSource('audience')}
                      choices={emailNames}
                      label="Audiencia"
                      disabled={scopedFormData?.status === "COMPLETED" || scopedFormData?.status === "SUCCESS"}
                    />
                  );
                }}
              </FormDataConsumer>
              <FormDataConsumer>
                {({ formData, scopedFormData, getSource, ...rest }) => {
                  return (
                    <DateTimePickerInput
                      {...rest}
                      source={getSource('startTime')}
                      label="Enviar el"
                      defaultValue={null}
                      disabled={scopedFormData?.status === "COMPLETED" || scopedFormData?.status === "SUCCESS"}
                    />
                  );
                }}
              </FormDataConsumer>
              <FormDataConsumer>
                {({ formData, scopedFormData, getSource, ...rest }) => {
                  if (!scopedFormData?.externalId) return null;

                  return (
                    <BooleanInput
                      {...rest}
                      source={getSource("remove")}
                      label="Eliminar"
                      defaultValue={false}
                      disabled={scopedFormData?.status === "COMPLETED" || scopedFormData?.status === "SUCCESS"}
                    />
                  );
                }}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default InvitationEdit;

import React from "react";
import {
  Show,
  FileField,
  TextField,
  DateField,
  SimpleShowLayout,
  TopToolbar,
  DeleteButton,
} from "react-admin";

const FileShowActions = ({ basePath, data, resource }) => (
  <TopToolbar>
      <DeleteButton basePath={basePath} record={data} resource={resource} />
  </TopToolbar>
);

const FileShow = (props) => {
  
  return (
    <Show {...props} actions={<FileShowActions />}>
      <SimpleShowLayout>
        <TextField source="name" label="Nombre" />
        <TextField source="size" label="Tamaño" />
        <TextField source="type" label="Formato" />
        <FileField source="shareUrl" title="shareUrl" label="URL Público" target="_blank" />
        <DateField source="createdAt" label="Creado en" />
        <DateField source="updatedAt" label="Modificado en" />
      </SimpleShowLayout>
    </Show>
  );
};

export default FileShow;

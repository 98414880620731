import React from 'react';
import { useInput } from 'react-admin';
import { DateTimePicker } from "@material-ui/pickers";

const DateTimePickerInput = props => {
  const {
    input: { name, onChange, ...rest },
    meta: { touched, error },
    isRequired
  } = useInput(props);

  return (
      <DateTimePicker
        clearable
        inputVariant="filled"
        name={name}
        label={props.label}
        onChange={onChange}
        error={!!(touched && error)}
        helperText={touched && error}
        required={isRequired}
        {...rest}
      />
  );
};

export default DateTimePickerInput;
import { Client } from '@microsoft/microsoft-graph-client';
import Cache from '@aws-amplify/cache';

const WORKBOOK_SESSION_HEADER = "workbook-session-id";
const WORKBOOK_SESSION_ID = "wrkb-ss";

function getAuthenticatedClient(accessToken) {
  // Initialize Graph client
  const client = Client.init({
    // Use the provided access token to authenticate
    // requests
    authProvider: (done) => {
      done(null, accessToken);
    }
  });

  return client;
}

async function createWorkbookSession(accessToken, driveId, workbookId) {
  const client = getAuthenticatedClient(accessToken);
  console.log("creating session...");
  const workbookSessionInfo = await client
    .api(`/drives/${driveId}/items/${workbookId}/workbook/createSession`)
    .post({
      persistChanges: false
    });
  
  return workbookSessionInfo;
}

async function refreshWorkbookSession(accessToken, driveId, workbookId, workbookSession) {
  const client = getAuthenticatedClient(accessToken);
  console.log("refreshing session...");
  await client
    .api(`/drives/${driveId}/items/${workbookId}/workbook/refreshSession`)
    .header(WORKBOOK_SESSION_HEADER, workbookSession)
    .post({});
  
  return;
}

async function closeWorkbookSession(accessToken, driveId, workbookId, workbookSession) {
  const client = getAuthenticatedClient(accessToken);
  console.log("closing session...");
  await client
    .api(`/drives/${driveId}/items/${workbookId}/workbook/closeSession`)
    .header(WORKBOOK_SESSION_HEADER, workbookSession)
    .post({});
  
  return;
}

async function getWorkbookSession(accessToken, driveId, workbookId) {
  const itemKey = `${WORKBOOK_SESSION_ID}:${driveId}:${workbookId}`;
  var cachedSession = Cache.getItem(itemKey);
  if (!cachedSession) {
    const sessionInfo = await createWorkbookSession(accessToken, driveId, workbookId);
    const expiresIn = new Date();
    expiresIn.setMinutes(expiresIn.getMinutes() + 5)
    Cache.setItem(itemKey, sessionInfo.id, { expires: expiresIn.getTime() });
    cachedSession = sessionInfo.id;
  } else {
    await refreshWorkbookSession(accessToken, driveId, workbookId, cachedSession);
    const expiresIn = new Date();
    expiresIn.setMinutes(expiresIn.getMinutes() + 5)
    Cache.setItem(itemKey, cachedSession, { expires: expiresIn.getTime() });
  }

  return cachedSession;
}

async function deleteWorkbookSession(accessToken, driveId, workbookId) {
  const itemKey = `${WORKBOOK_SESSION_ID}:${driveId}:${workbookId}`;
  var cachedSession = Cache.getItem(itemKey);
  if (cachedSession) {
    await closeWorkbookSession(accessToken, driveId, workbookId, cachedSession);
    Cache.removeItem(itemKey);
  }
  return;
}

export async function getUserDetails(accessToken) {
  const client = getAuthenticatedClient(accessToken);

  const user = await client
    .api('/me')
    .select('displayName,mail,mailboxSettings,userPrincipalName')
    .get();

  return user;
}

export async function getWorksheetUsedRange(accessToken, driveId, workbookId, worksheetIdentifier, selectAttributes = [ "address", "columnCount", "cellCount", "rowCount", "rowIndex", "text" ], options = { refresh: false }) {
  if (options.refresh) {
    await deleteWorkbookSession(accessToken, driveId, workbookId);
  }

  const workbookSession = await getWorkbookSession(accessToken, driveId, workbookId);

  const client = getAuthenticatedClient(accessToken);

  const range = await client
    .api(`/drives/${driveId}/items/${workbookId}/workbook/worksheets/${worksheetIdentifier}/usedRange`)
    .select(selectAttributes)
    .header(WORKBOOK_SESSION_HEADER, workbookSession)
    .get()
  
  return range;
}

export async function getRangeByAddress(accessToken, driveId, workbookId, worksheetIdentifier, address, options = { refresh: false }) {
  if (options.refresh) {
    await deleteWorkbookSession(accessToken, driveId, workbookId);
  }

  const workbookSession = await getWorkbookSession(accessToken, driveId, workbookId);

  const client = getAuthenticatedClient(accessToken);

  const range = await client
    .api(`/drives/${driveId}/items/${workbookId}/workbook/worksheets/${worksheetIdentifier}/range(address='${address}')`)
    .header(WORKBOOK_SESSION_HEADER, workbookSession)
    .get()
  
  return range;
}

export async function downloadWorksheet(accessToken, driveId, workbookId) {
  const client = getAuthenticatedClient(accessToken);

  const result = await client
    .api(`/drives/${driveId}/items/${workbookId}/content`)
    .get()
  
  return result;
}

export async function clearAllWorkbookSessions(accessToken) {
  const keys = await Cache.getAllKeys();
  for (let index = 0; index < keys.length; index++) {
    const key = keys[index];
    const [id, driveId, itemId] = key.split(":");
    if (id === WORKBOOK_SESSION_ID) {
      await deleteWorkbookSession(accessToken, driveId, itemId);
    }
  }
}
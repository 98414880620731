import React from "react";
import {
  Create,
  SimpleForm,
  BooleanInput,
  FileInput,
  FileField,
  required,
  regex,
  maxLength
} from "react-admin";
import _ from 'lodash';

const validation = [required()];

const MediaField = ({ record }) => {
  console.log(record);
  return (
    null
  );
};

const FileCreate = (props) => {
  return (
    <Create {...props} transform={(data) => {
      const { file: { rawFile }, ...rest } = data;
      const fileInput = {
        name: rawFile.name,
        key: rawFile.path,
        size: String(rawFile.size),
        type: rawFile.type,
        rawFile,
        ...rest
      };
      return fileInput;
    }}>
      <SimpleForm redirect="list">
        <FileInput
          source="file"
          label="Archivo Multimedia"
          validate={validation}
        >
          <FileField title="rawFile.path" target="_blank"/>
        </FileInput>
        <BooleanInput
          source="isPublic"
          label="Público"
        />
      </SimpleForm>
    </Create>
  );
};

export default FileCreate;

/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:0fe2571d-9982-45c5-abf8-803c1ee821c4",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_vhrDAuq1c",
    "aws_user_pools_web_client_id": "45cek60056ghnv2sgvt1rf5l2l",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://19977ck65h.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "graphqlApi",
            "endpoint": "https://721gf0fgd5.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        }
    ],
    "aws_mobile_analytics_app_id": "4e14b5475dd243188f2284371783bace",
    "aws_mobile_analytics_app_region": "us-east-1",
    "aws_user_files_s3_bucket": "aplusbackoffice54bcc42a30e34b6f9a2b832bf348acb062443-prod",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;

import React, { useState, useEffect, useRef } from "react";
import readXlsxFile from 'read-excel-file';
import { calEdDataSchema } from './schemas';

const calEdObjSchema = { prop: 'record', type: calEdDataSchema };

export default function useCalEdData() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const [byId, setById] = useState(null);
  const [file, setFile] = useState(null);
  const [obj, setObj] = useState(null);
  const objSchemaRef = useRef(null);

  useEffect(() => {
    if (file) {
      (async () => {
        try {
          setLoading(true);
          const { rows } = await readXlsxFile(file, {
            sheet: 'CALENDARIZACION',
            schema: calEdDataSchema
          });
          const result = rows.filter(({ project }) => Boolean(project)).map((item) => {
            const { school, topic } = item;
            return {
              ...item,
              name: `${school?.code} - ${school?.name} / ${topic?.code}`
            }
          });
          const reduced = result.reduce((accumulator, current) => {
            return {
              ...accumulator,
              [current.serviceNum]: current
            }
          }, {});
          setData(result);
          setById(reduced);
        } catch(e) {
          setError(e);
        } finally {
          setLoading(false);
        }
      })();
    } else if(obj) {
      (async () => {
        try {
          setLoading(true);
          if (obj && obj.text) {
            const result = [];
            for (let i = 0; i < obj.text.length; i++) {
              const values = obj.text[i];
              if (values[0]) {
                result.push({
                  name: values[0],
                  data: i,
                });
              }
            }
            const reduced = result.reduce((accumulator, current) => {
              return {
                ...accumulator,
                [current.name]: current.data
              }
            }, {});
            setData(result);
            setById(reduced);
          }
        } catch(e) {
          setError(e);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [file, obj]);

  const parseProperty = (key, value, getRaw = (key) => key) => {
    const { prop, type } = value;
    if (typeof type === "function") {
      return {
        prop,
        value: type(getRaw(key))
      };
    } else if (typeof type === "object") {
      const entries = Object.entries(type);
      let accumulator = {};
      for (let index = 0; index < entries.length; index++) {
        const element = entries[index];
        const parsed = parseProperty(element[0], element[1], getRaw);
        accumulator = {
          ...accumulator,
          [parsed.prop]: parsed.value
        }
      }
      return {
        prop,
        value: accumulator
      }
    } else {
      return {
        prop
      }
    }
  };

  const objToRecord = (source) => {
    const { name, data } = source;
    let result;
    if (name) {
      result = parseProperty('Record', calEdObjSchema, (key) => data[objSchemaRef.current[key]]);
    }
    console.log(result);
    return result;
  };

  const setHeadersMap = (value) => {
    objSchemaRef.current = value;
  };

  return {
    loading,
    error,
    data,
    byId,
    setFile,
    setObj,
    objToRecord,
    setHeadersMap
  };
}

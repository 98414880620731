import React from "react";
import {
  useInput,
} from "react-admin";
import Box from '@material-ui/core/Box';
import { TimePicker } from "@material-ui/pickers";

const TimePickerInput = (props) => {
  const {
    input: { value, ...input },
    meta: { touched, error },
    isRequired
  } = useInput(props);

  return (
    <Box py={2}>
      <TimePicker
        autoOk
        {...input}
        value={value === '' ? null : value}
        label={props.label}
        error={!!(touched && error)}
        helperText={touched && error}
        required={isRequired}
        inputVariant="filled"
        inputProps={{ style: { width: 232 } }}
        clearable
      />
    </Box>
  );
};

export default TimePickerInput;
import React, { useEffect, useState } from "react";
import { Amplify, I18n } from "@aws-amplify/core";
import AmplifyI18n from "amplify-i18n";
import API from "@aws-amplify/api";
import Auth from "@aws-amplify/auth";
import Storage from "@aws-amplify/storage"
import { Admin, Resource, Loading } from "react-admin";
import {
  buildAuthProvider,
  buildDataProvider
} from "react-admin-amplify";
import awsExports from "./aws-exports";
import LoginPage from './components/Auth/LoginPage';
import {
  UserList,
  UserCreate,
  UserEdit,
} from "./components/Users";
import {
  InvitationList,
  InvitationCreate,
  InvitationShow,
  InvitationEdit,
} from "./components/Invitation";
import {
  ParticipantList,
  ParticipantShow,
} from "./components/Participant";
import {
  TemplateList,
  TemplateShow,
  TemplateCreate,
  TemplateEdit,
} from "./components/Template";
import {
  FileList,
  FileShow,
  FileCreate,
  //FileEdit,
} from "./components/File";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import "./App.css";
import buildGraphQLProvider, { buildQuery } from 'ra-data-graphql-simple';
import * as auto_mutations from "./graphql/mutations";
import * as mutations from "./custom-graphql/mutations";
import * as queries from "./custom-graphql/queries";
import { createHttpLink, gql } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import customRoutes from './components/Utils/customRoutes';
import { createBrowserHistory as createHistory } from 'history';
import Layout from './components/Layout';
import { theme } from './utils/theme';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import DescriptionIcon from '@material-ui/icons/Description';
import ImageIcon from '@material-ui/icons/Image';
import polyglotI18nProvider from "ra-i18n-polyglot"; // Install this package
import engMessages from "ra-language-english"; // Install this package

const history = createHistory();

AmplifyI18n.configure(["en", "es"]);
I18n.setLanguage("en");

Amplify.configure({
  ...awsExports,
  Analytics: {
    disabled: true
  }
});

const enableAdminQueries = true;
const apiName = 'AdminQueries';
const graphqlApiName = 'graphqlApi';

const CDN_URL = process.env.REACT_APP_CDN_URL;

const authProvider = buildAuthProvider({ authGroups: ["Admins", "Contractor"] });

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_IS_LOCAL === "YES" ? "http://localhost:3000/v1/graphql" : `${awsExports.aws_cloud_logic_custom.find(({ name }) => name === graphqlApiName)?.endpoint}/v1/graphql`,
});

const authLink = setContext(async ({ operationName }, { headers }) => {
  if (operationName === "IntrospectionQuery") return { headers };

  let token;
  try {
    token = (await Auth.currentSession())?.getAccessToken()?.getJwtToken();
  } catch (e) { }

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : undefined,
    }
  }
});

const amplifyDataProvider = buildDataProvider({ queries, auto_mutations }, {
  enableAdminQueries: enableAdminQueries,
});

const defaultCreate = amplifyDataProvider.create;

amplifyDataProvider.create = async (resource, params) => {
  if (enableAdminQueries && resource === "cognitoUsers") {
    let path = '/createUser';
    let myInit = {
      body: params.data,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      }
    }
    const { Username, Attributes, ...rest } = await API.post(apiName, path, myInit);
    const attr = Attributes.reduce((accumulator, { Name, Value }) => ({
      ...accumulator,
      [Name]: Value
    }), {});
    return {
      data: {
        id: Username,
        ...rest,
        ...attr
      }
    };
  } else {
    return (await defaultCreate(resource, params));
  }
};

const myBuildQuery = introspection => (fetchType, resource, params) => {
  const builtQuery = buildQuery(introspection)(fetchType, resource, params);
  
  if (resource === 'Invitation' && fetchType === 'GET_LIST') {
    return {
      // Use the default query variables and parseResponse
      ...builtQuery,
      // Override the query
      query: gql(queries.listInvitations),
    };
  }
  else if (resource === 'Invitation' && fetchType === 'GET_ONE') {
    return {
      // Use the default query variables and parseResponse
      ...builtQuery,
      // Override the query
      query: gql(queries.invitation),
    };
  }
  else if (resource === 'Participant' && fetchType === 'GET_ONE') {
    return {
      // Use the default query variables and parseResponse
      ...builtQuery,
      // Override the query
      query: gql(queries.participant),
    };
  }
  else if (resource === 'Invitation' && fetchType === 'UPDATE') {
    return {
      // Use the default query variables and parseResponse
      ...builtQuery,
      // Override the query
      query: gql(mutations.updateInvitation),
    };
  } else if (resource === 'Invitation' && fetchType === 'CREATE') {
    return {
      // Use the default query variables and parseResponse
      ...builtQuery,
      // Override the query
      query: gql(mutations.createInvitation),
    };
  } else if (resource === 'Template' && fetchType === 'GET_ONE') {
    return {
      // Use the default query variables and parseResponse
      ...builtQuery,
      // Override the query
      query: gql(queries.template),
    };
  }

  return builtQuery;
};

const i18nProvider = polyglotI18nProvider((locale) => engMessages, "en", {
  allowMissing: true,
  onMissingKey: (key, _, __) => key,
});

function App() {
  const [dataProvider, setDataProvider] = useState(null);

  useEffect(() => {
    const build = async () => {
      let dp = await buildGraphQLProvider({
        clientOptions: {
          link: authLink.concat(httpLink),
          //uri: `${awsExports.aws_cloud_logic_custom.find(({ name }) => name === graphqlApiName)?.endpoint}/v1/graphql`
        },
        buildQuery: myBuildQuery,
      });
      
      setDataProvider(() => ((fetchType, resource, params) => {
        if (resource === "cognitoUsers" || resource === "cognitoGroups") {
          switch (fetchType) {
            case "GET_LIST":
              return amplifyDataProvider.getList(resource, params);
            case "GET_MANY_REFERENCE":
              return amplifyDataProvider.getManyReference(resource, params);
            case "GET_ONE":
              return amplifyDataProvider.getOne(resource, params);
            case "CREATE":
              return amplifyDataProvider.create(resource, params);
            case "UPDATE":
              return amplifyDataProvider.update(resource, params);
            case "DELETE":
              return amplifyDataProvider.delete(resource, params);
            default:
              break;
          }
        } else if (resource === "File") {
          switch (fetchType) {
            case "CREATE":
              if (params.data.rawFile && params.data.rawFile instanceof File) {
                const { rawFile, isPublic, ...paramsData } = params.data;
                const fileKey = `${isPublic ? 'shared' : 'internal'}/${paramsData.key}`;
                return Storage.put(fileKey, rawFile, { contentType: paramsData.type })
                  .then(() => {
                    const newParams = {
                      ...params,
                      data: {
                        ...paramsData,
                        key: `public/${fileKey}`,
                        shareUrl: isPublic ? `${CDN_URL}/${paramsData.key}` : undefined
                      }
                    };
                    return dp(fetchType, resource, newParams);
                  });
              }
            default:
              break;
          }
        }
        return dp(fetchType, resource, params);
      }));
    };

    build();
  }, []);

  if (!dataProvider) {
    return (
      <Loading loadingPrimary="Loading" loadingSecondary="Initializing app..." />
    );
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Admin
        authProvider={authProvider}
        dataProvider={dataProvider}
        i18nProvider={i18nProvider}
        loginPage={LoginPage}
        customRoutes={customRoutes}
        history={history}
        layout={Layout}
        theme={theme}
      >
        {permissions => [
          <Resource
            name="Invitation"
            list={InvitationList}
            create={InvitationCreate}
            show={InvitationShow}
            edit={InvitationEdit}
            icon={InsertInvitationIcon}
            options={{
              label: 'Invitaciones'
            }}
          />,
          <Resource
            name="Participant"
            list={ParticipantList}
            show={ParticipantShow}
            icon={PeopleAltIcon}
            options={{
              label: 'Participantes'
            }}
          />,
          <Resource
            name="Template"
            list={TemplateList}
            create={TemplateCreate}
            show={TemplateShow}
            edit={TemplateEdit}
            icon={DescriptionIcon}
            options={{
              label: 'Plantillas'
            }}
          />,
          <Resource
            name="File"
            list={FileList}
            create={FileCreate}
            show={FileShow}
            icon={ImageIcon}
            options={{
              label: 'Multimedias'
            }}
          />,
          permissions.includes('Admins')
          ?
          <Resource
            name="cognitoUsers"
            list={UserList}
            create={UserCreate}
            edit={UserEdit}
            icon={AccountCircleIcon}
            options={{
              label: 'Usuarios'
            }}
          />
          :
          null,
          permissions.includes('Admins')
          ?
          <Resource
            name="cognitoGroups"
          />
          :
          null
        ]}
      </Admin>
    </MuiPickersUtilsProvider>
  );
}

export default App;


import React from "react";
import {
  Datagrid,
  DateField,
  List,
  TextField,
  ShowButton,
} from "react-admin";

const ParticipantList = (props) => {
  return (
    <List
      bulkActionButtons={false}
      {...props}
      filter={{ not_role: ["Provider", "Principal"] }}
    >
      <Datagrid>
        <TextField
          label="ID"
          source="id"
        />
        <TextField
          label="Núm. Kronos"
          source="kronosNumber"
        />
        <TextField
          label="Nombre"
          source="name"
        />
        <TextField
          label="Puesto"
          source="role"
        />
        <DateField
          label="Añadido el"
          source="createdAt"
        />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export default ParticipantList;

import React from 'react';
import { AmplifyAuthenticator, AmplifySignIn, AmplifyRequireNewPassword, AmplifyForgotPassword } from "@aws-amplify/ui-react";
import { useRedirect, Notification } from 'react-admin';
import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import { theme } from '../../utils/theme';
import { ReactComponent as Logo } from '../../assets/aplus-logo.svg';

export default function LoginPage({}) {
  const redirect = useRedirect();

  const handleSingedIn = async (nextAuthState) => {
    if (nextAuthState === "signedin") {
      redirect("/");
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline/>
      <Grid container justify='space-evenly' alignItems='center'>
        <Grid item>
          <Logo width={400} height={400} />
        </Grid>
        <Grid item>
          <AmplifyAuthenticator handleAuthStateChange={handleSingedIn}>
            <AmplifySignIn
              slot="sign-in"
              hideSignUp
              usernameAlias="email"
            />
            <AmplifyRequireNewPassword />
            <AmplifyForgotPassword
              slot="forgot-password"
              usernameAlias="email"
            />
          </AmplifyAuthenticator>
        </Grid>
      </Grid>
      <Notification />
    </ThemeProvider>
  );
};
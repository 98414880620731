
import React, { useState } from "react";
import {
  Show,
  TextField,
  DateField,
  ArrayField,
  FunctionField,
  BooleanField,
  ReferenceField,
  Datagrid,
  TabbedShowLayout,
  Tab,
} from "react-admin";
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { getTime } from '../Utils/helperFx';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import IconImageEye from "@material-ui/icons/RemoveRedEye";
import IconKeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const TimeField = (props) => {
  if (!props.record[props.source]) return null;

  return (
    <Box my={1}>
      <Typography variant="caption" component="p" color="textSecondary">{props.label || props.source}</Typography>
      <FunctionField {...props} render={(record, source) => getTime(new Date(record[source]))} />
    </Box>
  );
}

const InvitationShow = (props) => {

  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="Resumen">
          <TextField source="id" />
          <TextField source="serviceNum" label="Núm. Servicio" />
          <TextField source="projectPlan" label="Plan de Trabajo" />
          <TextField source="serviceCode" label="Cód. Servicio" />
          <TextField source="additionalServiceCode" multiline label="Cód. Servicio Adicional" />
          <TextField source="instructions_1" label="Instrucción Adicional (Primera Línea)" />
          <TextField source="instructions_2" label="Instrucción Adicional (Segunda Línea)" />
          <TextField source="topic.code" label="Cód. Tema" />
          <TextField source="topic.name" label="Nombre" />
          <TextField source="topic.type" label="Tipo" />
          <TextField source="topic.modality" label="Modalidad" />
          <DateField source="serviceFinalDate" label="Fecha Final" />
          <TimeField source="startTime" label="Hora inicio" />
          <TimeField source="endTime" label="Hora final" />
          <DateField source="serviceContDate" label="Fecha Cont. del Servicio" />
          <TimeField source="serviceContStartTime" label="Hora Inicio" />
          <TimeField source="serviceContEndTime" label="Hora Salida" />
          <DateField source="serviceStartDate" label="Fecha Inicio del Servicio" />
          <TimeField source="serviceStartStartTime" label="Hora Inicio" />
          <TimeField source="serviceStartEndTime" label="Hora Salida" />
          <TextField source="serviceHours" multiline label="Horas Servicio" />
          <TextField source="numberOfParticipants" label="Núm. Participantes" />
          <TextField source="numberOfParticipantsAdic" label="Núm. Participantes Adic." />
          <TextField source="numberOfParticipantsAdicNoCharge" label="Núm. Participantes Adic. Sin Cargos" />
          <TextField source="totalOfParticipants" label="Total Participantes" />
          <TextField source="schedule" label="Agenda" />
          <TextField source="handbook" multiline label="Manual" />
          <TextField source="listOfParticipants" label="Lista de Participantes" />
          <TextField source="minimumParticipants" label="Participantes Minimo" />
          <DateField source="createdAt" label="Creado en" />
          <DateField source="updatedAt" label="Modificado en" />
        </Tab>
        <Tab label="A+">
          <TextField source="executive.name" label="Nombre del Ejecutivo" />
          <TextField source="executive.phone" label="Núm. Teléfono del Ejecutivo" />
          <TextField source="executive.email" label="Email del Ejecutivo" />
          <TextField source="contact.name" label="Nombre del Contacto A+" />
          <TextField source="contact.phone" label="Núm. Teléfono del Contacto A+" />
          <TextField source="contact.email" label="Email del Contacto A+" />
          <TextField source="resource.name" label="Nombre del Recurso" />
          <TextField source="resource.phone" label="Núm. Telefónico del Recurso" />
          <TextField source="resource.email" label="Email del Recurso" />
          <TextField source="assistant.name" label="Nombre del Asistente" />
          <TextField source="assistant.phone" label="Núm. Telefónico del Asistente" />
          <TextField source="assistant.email" label="Email del Asistente" />
        </Tab>
        <Tab label="Configuración">
          <TextField source="subject" label="Título del Email" />
          <TextField source="virtualMeet.title" label="Título de la Reunión" />
          <TextField source="virtualMeet.platform" label="Lugar de Servicio" />
          <TextField source="virtualMeet.username" label="Usuario de la Reunión Virtual" />
          <TextField source="virtualMeet.url" label="Enlace de la Reunión Virtual" />
          <ArrayField source="extraCC" label="Extra CC">
            <Datagrid>
              <TextField source="name" label="Nombre"/>
              <TextField source="email" label="Correo electrónico" />
            </Datagrid>
          </ArrayField>
        </Tab>
        <Tab label="Escuela">
          <TextField source="school.code" label="Cód." />
          <TextField source="school.name" label="Nombre" />
          <TextField source="school.type" label="Tipo de Institución" />
          <TextField source="school.region" label="Región" />
          <TextField source="school.principal.name" label="Nombre del Director" />
          <TextField source="school.principal.phone" label="Núm. Telefónico del Director" />
          <TextField source="school.principal.email" label="Email del Director" />
          <TextField source="school.additionalContact.name" label="Persona Contacto Adicional" />
          <TextField source="school.additionalContact.phone" label="Tel. Móvil Adicional" />
          <TextField source="school.additionalContact.email" label="Email Adicional" />
        </Tab>
        <Tab label="Participantes">
          <ArrayField source="attendees" addLabel={false}>
            <Datagrid>
              <TextField source="participant.name" label="Nombre" />
              <TextField source="participant.email" label="Email Primario" />
              <TextField source="participant.role" label="Puesto" />
            </Datagrid>
          </ArrayField>
        </Tab>
        <Tab label="Campañas">
          <ArrayField source="campaigns" addLabel={false}>
            <Datagrid>
              <TextField source="id" />
              <ReferenceField
                label="Plantilla"
                source="templateId"
                reference="Template"
              >
                <TextField source="name" />
              </ReferenceField>
              <TextField source="audience" label="Audiencia" />
              <TextField source="status" label="Status" />
              <DateField source="startTime" label="Enviar el" showTime options={{ timeZone: 'America/Puerto_Rico' }} />
              <DateField source="createdAt" label="Creada el" options={{ timeZone: 'America/Puerto_Rico' }} />
              <CampaignPreviewButton />
            </Datagrid>
          </ArrayField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default InvitationShow;

function CampaignPreviewButton({ record }) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        startIcon={<IconImageEye />}
        onClick={handleOpen}
      >
        Mostrar
      </Button>
      <Drawer
        anchor="right"
        open={open}
        onClose={handleClose}
      >
        <Box py={1} px={1}>
          <Button
            startIcon={<IconKeyboardArrowRight />}
            onClick={handleClose}
          >
            Cerrar
          </Button>
        </Box>
        <Box px={2} pt={1}>
          <Typography variant="h6">
            {`Recipientes de campaña ${record.id}`}
          </Typography>
        </Box>
        <Box width={500}>
          <TableContainer>
            <Table aria-label="recipients table">
              <TableHead>
                <TableRow>
                  <TableCell>Nombre</TableCell>
                  <TableCell>E-mail</TableCell>
                  <TableCell>Estado</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {record?.recipients?.map(({ participant, status }) => (
                  <TableRow key={`${participant.id}`}>
                    <TableCell component="th" scope="row">
                      {participant.name}
                    </TableCell>
                    <TableCell>{participant.email}</TableCell>
                    <TableCell>{status}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Drawer>
    </>
  );
}

import React, { useState } from "react";
import { useMSAuthProvider } from '../MS/AuthProvider';
import { downloadWorksheet, getWorksheetUsedRange, getRangeByAddress, clearAllWorkbookSessions } from '../MS/GraphService';

export default function useOneDrive() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { loading: authProviderLoading, error: authProviderError, isAuthenticated, silentlyGetAccessToken } = useMSAuthProvider();

  // 5DF538A7184795A1!108
  const downloadFile = async (driveId, itemId) => {
    let file;
    try {
      if(isAuthenticated) {
        setLoading(true);
        setError(null);
        const token = await silentlyGetAccessToken();
        const result = await downloadWorksheet(token, driveId, itemId);
        file = new File([result.fileBlob], result.name, {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
      }
    } catch(e) {
      console.log(e);
      setError(e);
    } finally {
      setLoading(false);
    }
    return file;
  };

  const downloadFileContent = async (driveId, itemId, partition, select, refresh = false) => {
    let result;
    try {
      if(isAuthenticated) {
        setLoading(true);
        setError(null);
        const token = await silentlyGetAccessToken();
        result = await getWorksheetUsedRange(token, driveId, itemId, partition, select, { refresh });
      }
    } catch(e) {
      console.log(e);
      setError(e);
    } finally {
      setLoading(false);
    }
    return result;
  };

  const getRangeContent = async (driveId, itemId, partition, address, refresh = false) => {
    let result;
    try {
      if(isAuthenticated) {
        setLoading(true);
        setError(null);
        const token = await silentlyGetAccessToken();
        result = await getRangeByAddress(token, driveId, itemId, partition, address, { refresh });
      }
    } catch(e) {
      console.log(e);
      setError(e);
    } finally {
      setLoading(false);
    }
    return result;
  };

  const clearAllCache = async () => {
    const token = await silentlyGetAccessToken();
    await clearAllWorkbookSessions(token);
  }

  return {
    isAuthenticated,
    loading: loading || authProviderLoading,
    error: error || authProviderError,
    downloadFile,
    downloadFileContent,
    getRangeContent,
    clearAllCache
  };
}

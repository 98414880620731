import React, { useRef, useEffect, useState, forwardRef } from 'react';
import EmailEditor from 'react-email-editor';
import { useInput, ReferenceInput, SelectInput, useDataProvider } from 'react-admin';
import { mergeTags } from '../Utils/Template/tags.json';
import { Storage } from 'aws-amplify';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Box from '@material-ui/core/Box';

const CDN_URL = process.env.REACT_APP_CDN_URL;

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Editor = (props) => {
  const emailEditorRef = useRef(null);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const dataProvider = useDataProvider();
  const {
    input: { name, onChange: onHtmlChange, ...rest },
    meta: { touched, error },
    isRequired
  } = useInput({
    source: "emailTemplate.htmlPart",
    ...props
  });
  const {
    input: { onChange: onDesignChange, value }
  } = useInput({
    source: "design",
    ...props
  });

  const {
    input: { onChange: onCopyChange, value: copyValue }
  } = useInput({
    source: "copy_id",
    ...props
  });

  useEffect(() => {
    if (copyValue) {
      (async () => {
        try {
          setLoading(true);
          const { data } = await dataProvider.getOne('Template', { id: copyValue });
          emailEditorRef.current?.editor.loadDesign(data.design);
          onDesignChange({ target: { value: data.design } });
          onHtmlChange({ target: { value: data.emailTemplate.htmlPart } });
        } catch(e) {
          console.log(e);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [copyValue]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    onCopyChange({ target: { value: null } });
  };

  const exportHtml = () => {
    emailEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      onDesignChange({ target: { value: design } });
      onHtmlChange({ target: { value: html } });
    });
  };

  const onUpload = async (fileInfo, done) => {
    try {
      const file = fileInfo.attachments[0];
      await Storage.put(`shared/${file.name}`, file, {
        progressCallback: (progress) => {
          const percentage = (progress.loaded/progress.total) * 100;
          if (percentage !== 100) {
            console.log(`Uploaded: ${percentage}`);
            done({
              progress: percentage
            });
          }
        }
      });
      done({
        progress: 100,
        url: `${CDN_URL}/${file.name}`
      });
    } catch (err) {
      console.error('Unexpected error while uploading', err);
    }
  }

  const onReady = () => {
    // editor is ready
    console.log('onReady');

    // Set merge tags
    emailEditorRef.current?.setMergeTags(mergeTags);

    // Load template here
    emailEditorRef.current?.editor.loadDesign(value);

    // Autosave template updates
    emailEditorRef.current?.editor.addEventListener('design:updated', exportHtml);

    // Upload image files
    emailEditorRef.current?.registerCallback('image', onUpload);
  };

  return (
    <Box pt={3}>
      <Typography component='p' variant="caption" color="textSecondary" gutterBottom>Design</Typography>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open editor
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        keepMounted
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Template Editor
            </Typography>
            <ReferenceInput
              source="copy_id"
              reference="Template"
              label="Use Template" 
            >
              <SelectInput optionText="name" disabled={loading} />
            </ReferenceInput>
          </Toolbar>
        </AppBar>
        <EmailEditor
          ref={emailEditorRef}
          onReady={onReady}
        />
      </Dialog>
    </Box>
  );
};

export default Editor;
export const createInvitation = /* GraphQL */ `
  mutation createInvitation(
    $assistant: ParticipantCreateInput,
    $attendees: [ParticipantCreateInput!],
    $campaigns: [CampaignCreateInput!],
    $contact: JSON,
    $endTime: DateTime,
    $executive: JSON,
    $projectPlan: String,
    $school: SchoolCreateInput!,
    $serviceCode: String,
    $additionalServiceCode: String,
    $serviceFinalDate: DateTime,
    $serviceNum: String!,
    $startTime: DateTime,
    $serviceContDate: DateTime,
    $serviceContStartTime: DateTime,
    $serviceContEndTime: DateTime,
    $serviceStartDate: DateTime,
    $serviceStartStartTime: DateTime,
    $serviceStartEndTime: DateTime,
    $serviceHours: String,
    $numberOfParticipants: String,
    $numberOfParticipantsAdic: String,
    $numberOfParticipantsAdicNoCharge: String,
    $totalOfParticipants: String,
    $schedule: String,
    $handbook: String,
    $listOfParticipants: String,
    $minimumParticipants: String,
    $subject: String,
    $topic: JSON,
    $virtualMeet: JSON,
    $instructions_1: String,
    $instructions_2: String,
    $extraCC: [JSON!],
    $resource: ParticipantCreateInput!
  ) {
  data: createInvitation(
    assistant: $assistant,
    attendees: $attendees,
    campaigns: $campaigns,
    contact: $contact,
    endTime: $endTime,
    serviceContDate: $serviceContDate,
    serviceContStartTime: $serviceContStartTime,
    serviceContEndTime: $serviceContEndTime,
    serviceStartDate: $serviceStartDate,
    serviceStartStartTime: $serviceStartStartTime,
    serviceStartEndTime: $serviceStartEndTime,
    serviceHours: $serviceHours,
    numberOfParticipants: $numberOfParticipants,
    numberOfParticipantsAdic: $numberOfParticipantsAdic,
    numberOfParticipantsAdicNoCharge: $numberOfParticipantsAdicNoCharge,
    totalOfParticipants: $totalOfParticipants,
    schedule: $schedule,
    handbook: $handbook,
    listOfParticipants: $listOfParticipants,
    minimumParticipants: $minimumParticipants,
    executive: $executive,
    projectPlan: $projectPlan,
    school: $school,
    serviceCode: $serviceCode,
    additionalServiceCode: $additionalServiceCode,
    serviceFinalDate: $serviceFinalDate,
    serviceNum: $serviceNum,
    startTime: $startTime,
    subject: $subject,
    topic: $topic,
    virtualMeet: $virtualMeet
    instructions_1: $instructions_1
    instructions_2: $instructions_2
    extraCC: $extraCC
    resource: $resource
  ) {
    id
    serviceNum
    serviceCode
    additionalServiceCode
    serviceFinalDate
    startTime
    endTime
    serviceContDate
    serviceContStartTime
    serviceContEndTime
    serviceStartDate
    serviceStartStartTime
    serviceStartEndTime
    serviceHours
    numberOfParticipants
    numberOfParticipantsAdic
    numberOfParticipantsAdicNoCharge
    totalOfParticipants
    schedule
    handbook
    listOfParticipants
    minimumParticipants
    virtualMeet
    minimumParticipants
    transferLink
    school {
      id
      __typename
      code
      createdAt
      name
      type
      region
      additionalContact
      principal {
        id
        __typename
        altEmail
        createdAt
        email
        name
        phone
        role
        kronosNumber
        updatedAt
      }
      updatedAt
    }
    attendees {
      participant {
        id
        __typename
        altEmail
        createdAt
        email
        name
        phone
        role
        kronosNumber
        updatedAt
      }
      createdAt
      updatedAt
      __typename
    }
    topic
    assistant {
      id
      __typename
      altEmail
      createdAt
      email
      name
      phone
      role
      kronosNumber
      updatedAt
    }
    campaigns {
      __typename
      id
      externalId
      segmentId
      template {
        __typename
        id
        name
        description
        updatedAt
        createdAt
      }
      audience
      startTime
      recipients {
        status
        statistics
        errorCode
        sysMessage
        participant {
          id
          __typename
          altEmail
          createdAt
          email
          name
          phone
          role
          kronosNumber
          updatedAt
        }
      }
      status
      statistics
      errorCode
      sysMessage
      createdAt
    }
    executive
    contact
    projectPlan
    subject
    instructions_1
    instructions_2
    extraCC
    resource {
      id
      __typename
      altEmail
      createdAt
      email
      name
      phone
      role
      kronosNumber
      updatedAt
    }
    createdAt
    updatedAt
  }
}
`;

export const updateInvitation = /* GraphQL */ `
  mutation updateInvitation(
    $assistant: ParticipantUpdateInput,
    $attendees: [AttendeeUpdateInput!],
    $campaigns: [CampaignUpdateInput!],
    $contact: JSON,
    $endTime: DateTime,
    $executive: JSON,
    $id: Int!,
    $projectPlan: String,
    $school: SchoolUpdateInput!,
    $serviceCode: String,
    $additionalServiceCode: String,
    $serviceFinalDate: DateTime,
    $serviceNum: String!,
    $startTime: DateTime,
    $serviceContDate: DateTime,
    $serviceContStartTime: DateTime,
    $serviceContEndTime: DateTime,
    $serviceStartDate: DateTime,
    $serviceStartStartTime: DateTime,
    $serviceStartEndTime: DateTime,
    $serviceHours: String,
    $numberOfParticipants: String,
    $numberOfParticipantsAdic: String,
    $numberOfParticipantsAdicNoCharge: String,
    $totalOfParticipants: String,
    $schedule: String,
    $handbook: String,
    $listOfParticipants: String,
    $minimumParticipants: String,
    $subject: String,
    $topic: JSON,
    $virtualMeet: JSON,
    $instructions_1: String,
    $instructions_2: String,
    $extraCC: [JSON!],
    $resource: ParticipantUpdateInput!
  ) {
  data: updateInvitation(
    assistant: $assistant,
    attendees: $attendees,
    campaigns: $campaigns,
    contact: $contact,
    endTime: $endTime,
    executive: $executive,
    id: $id,
    projectPlan: $projectPlan,
    school: $school,
    serviceCode: $serviceCode,
    additionalServiceCode: $additionalServiceCode,
    serviceFinalDate: $serviceFinalDate,
    serviceNum: $serviceNum,
    startTime: $startTime,
    serviceContDate: $serviceContDate,
    serviceContStartTime: $serviceContStartTime,
    serviceContEndTime: $serviceContEndTime,
    serviceStartDate: $serviceStartDate,
    serviceStartStartTime: $serviceStartStartTime,
    serviceStartEndTime: $serviceStartEndTime,
    serviceHours: $serviceHours,
    numberOfParticipants: $numberOfParticipants,
    numberOfParticipantsAdic: $numberOfParticipantsAdic,
    numberOfParticipantsAdicNoCharge: $numberOfParticipantsAdicNoCharge,
    totalOfParticipants: $totalOfParticipants,
    schedule: $schedule,
    handbook: $handbook,
    listOfParticipants: $listOfParticipants,
    minimumParticipants: $minimumParticipants,
    subject: $subject,
    topic: $topic,
    virtualMeet: $virtualMeet,
    instructions_1: $instructions_1,
    instructions_2: $instructions_2,
    extraCC: $extraCC
    resource: $resource
  ) {
    id
    serviceNum
    serviceCode
    additionalServiceCode
    serviceFinalDate
    startTime
    endTime
    serviceContDate
    serviceContStartTime
    serviceContEndTime
    serviceStartDate
    serviceStartStartTime
    serviceStartEndTime
    serviceHours
    numberOfParticipants
    numberOfParticipantsAdic
    numberOfParticipantsAdicNoCharge
    totalOfParticipants
    schedule
    handbook
    listOfParticipants
    minimumParticipants
    virtualMeet
    minimumParticipants
    transferLink
    school {
      id
      __typename
      code
      createdAt
      name
      type
      region
      additionalContact
      principal {
        id
        __typename
        altEmail
        createdAt
        email
        name
        phone
        role
        kronosNumber
        updatedAt
      }
      updatedAt
    }
    attendees {
      participant {
        id
        __typename
        altEmail
        createdAt
        email
        name
        phone
        role
        kronosNumber
        updatedAt
      }
      createdAt
      updatedAt
      __typename
    }
    topic
    assistant {
      id
      __typename
      altEmail
      createdAt
      email
      name
      phone
      role
      kronosNumber
      updatedAt
    }
    campaigns {
      __typename
      id
      externalId
      segmentId
      template {
        __typename
        id
        name
        description
        updatedAt
        createdAt
      }
      audience
      startTime
      recipients {
        status
        statistics
        errorCode
        sysMessage
        participant {
          id
          __typename
          altEmail
          createdAt
          email
          name
          phone
          role
          kronosNumber
          updatedAt
        }
      }
      status
      statistics
      errorCode
      sysMessage
      createdAt
    }
    executive
    contact
    projectPlan
    subject
    instructions_1
    instructions_2
    extraCC
    resource {
      id
      __typename
      altEmail
      createdAt
      email
      name
      phone
      role
      kronosNumber
      updatedAt
    }
    createdAt
    updatedAt
  }
}
`;

import React, { useEffect } from "react";
import { useMSAuthProvider } from '../MS/AuthProvider';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

export default function Connect() {
  const { loading, error, isAuthenticated } = useMSAuthProvider();

  useEffect(() => {
    if (isAuthenticated) {
      window.location.href = `${process.env.REACT_APP_HOST_URL}/`;
    }
  }, [isAuthenticated]);
  
  if ((loading || error) && !isAuthenticated) {
    return (
      <Box bgcolor="#f5f5f5" height="100vh" display="flex" alignItems="center">
        <Container maxWidth="lg">
          <Typography
            variant="h5"
            align="center"
          >
            {error
              ?
              "Oops, connection failed..."
              :
              "Connecting Microsoft OneDrive..."}
          </Typography>
        </Container>
      </Box>
    );
  }

  return null;
}

import React from "react";
import {
  Show,
  TextField,
  DateField,
  RichTextField,
  ArrayField,
  ChipField,
  SingleFieldList,
  SimpleShowLayout,
} from "react-admin";

const TemplateShow = (props) => {
  
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="name" label="Nombre" />
        <TextField source="description" label="Descripción" />
        <ArrayField
          source="attachments"
          label="Attachments"
        >
          <SingleFieldList>
            <ChipField source="file.name" />
          </SingleFieldList>
        </ArrayField>
        <RichTextField source="emailTemplate.htmlPart" label="Contenido" />
        <DateField source="createdAt" label="Creado en" />
        <DateField source="updatedAt" label="Modificado en" />
      </SimpleShowLayout>
    </Show>
  );
};

export default TemplateShow;

import React, { Component, useRef, useState, useEffect } from 'react';
import { PublicClientApplication } from '@azure/msal-browser';
import { getUserDetails } from './GraphService';
import { config } from './Config';

export function useMSAuthProvider() {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState({});

  // Initialize the MSAL application object
  const { current: publicClientApplication } = useRef(new PublicClientApplication({
    auth: {
      clientId: config.appId,
      redirectUri: config.redirectUri,
      authority: config.authorityURL
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: true
    }
  }));

  useEffect(() => {
    // If MSAL already has an account, the user is already logged in
    const accounts = publicClientApplication.getAllAccounts();

    if (accounts && accounts.length > 0) {
      // Enhance user object with data from Graph
      getUserProfile();
    }
  }, []);

  const login = async () => {
    try {
      // Login via popup
      await publicClientApplication.loginPopup(
        {
          scopes: config.scopes,
          prompt: "select_account"
        });

      // After login, get the user's profile
      await getUserProfile();
    }
    catch (err) {
      setIsAuthenticated(false);
      setUser({});
      setError(normalizeError(err));
    }
  }

  const logout = async () => {
    await publicClientApplication.loginPopup();
  }

  const getAccessToken = async (scopes = config.scopes) => {
    try {
      const accounts = publicClientApplication
        .getAllAccounts();

      if (accounts.length <= 0) throw new Error('login_required');
      // Get the access token silently
      // If the cache contains a non-expired token, this function
      // will just return the cached token. Otherwise, it will
      // make a request to the Azure OAuth endpoint to get a token
      setLoading(true);
      var silentResult = await publicClientApplication
        .acquireTokenSilent({
          scopes: scopes,
          account: accounts[0]
        });

      return silentResult.accessToken;
    } catch (err) {
      // If a silent request fails, it may be because the user needs
      // to login or grant consent to one or more of the requested scopes
      if (isInteractionRequired(err)) {
        var interactiveResult = await publicClientApplication
          .acquireTokenPopup({
            scopes: scopes
          });

        return interactiveResult.accessToken;
      } else {
        throw err;
      }
    } finally {
      setLoading(false);
    }
  }

  const silentlyGetAccessToken = async (scopes = config.scopes) => {
    try {
      const accounts = publicClientApplication
        .getAllAccounts();

      if (accounts.length <= 0) throw new Error('login_required');
      // Get the access token silently
      // If the cache contains a non-expired token, this function
      // will just return the cached token. Otherwise, it will
      // make a request to the Azure OAuth endpoint to get a token
      var silentResult = await publicClientApplication
        .acquireTokenSilent({
          scopes: scopes,
          account: accounts[0]
        });

      return silentResult.accessToken;
    } catch (err) {
      // If a silent request fails, it may be because the user needs
      // to login or grant consent to one or more of the requested scopes
      if (isInteractionRequired(err)) {
        var interactiveResult = await publicClientApplication
          .acquireTokenPopup({
            scopes: scopes
          });

        return interactiveResult.accessToken;
      } else {
        throw err;
      }
    }
  }

  const getUserProfile = async () => {
    try {
      var accessToken = await getAccessToken(config.scopes);

      if (accessToken) {
        // Get the user's profile from Graph
        setLoading(true);
        var user = await getUserDetails(accessToken);
        setIsAuthenticated(true);
        setUser({
          displayName: user.displayName,
          email: user.mail || user.userPrincipalName,
        });
        setError(null);
      }
    }
    catch (err) {
      setIsAuthenticated(false);
      setUser({});
      setError(normalizeError(err));
    }
    finally {
      setLoading(false);
    }
  }

  const setErrorMessage = (message, debug) => {
    setError({ message: message, debug: debug });
  }

  const normalizeError = (error) => {
    var normalizedError = {};
    if (typeof (error) === 'string') {
      var errParts = error.split('|');
      normalizedError = errParts.length > 1 ?
        { message: errParts[1], debug: errParts[0] } :
        { message: error };
    } else {
      normalizedError = {
        message: error.message,
        debug: JSON.stringify(error)
      };
    }
    return normalizedError;
  }

  const isInteractionRequired = (error) => {
    if (!error.message || error.message.length <= 0) {
      return false;
    }

    return (
      error.message.indexOf('consent_required') > -1 ||
      error.message.indexOf('interaction_required') > -1 ||
      error.message.indexOf('login_required') > -1 ||
      error.message.indexOf('no_account_in_silent_request') > -1
    );
  }

  return {
    loading,
    error,
    isAuthenticated,
    user,
    login: () => login(),
    logout: () => logout(),
    getAccessToken: (scopes) => getAccessToken(scopes),
    silentlyGetAccessToken: (scopes) => silentlyGetAccessToken(scopes),
    setError: (message, debug) => setErrorMessage(message, debug)
  };
}

export default function withMSAuthProvider(WrappedComponent) {
  return class extends Component {
    publicClientApplication;

    constructor(props) {
      super(props);
      this.state = {
        error: null,
        isAuthenticated: false,
        user: {}
      };

      // Initialize the MSAL application object
      this.publicClientApplication = new PublicClientApplication({
        auth: {
          clientId: config.appId,
          redirectUri: config.redirectUri
        },
        cache: {
          cacheLocation: "sessionStorage",
          storeAuthStateInCookie: true
        }
      });
    }

    componentDidMount() {
      // If MSAL already has an account, the user
      // is already logged in
      const accounts = this.publicClientApplication.getAllAccounts();

      if (accounts && accounts.length > 0) {
        // Enhance user object with data from Graph
        this.getUserProfile();
      }
    }

    render() {
      return <WrappedComponent
        error={this.state.error}
        isAuthenticated={this.state.isAuthenticated}
        user={this.state.user}
        login={() => this.login()}
        logout={() => this.logout()}
        getAccessToken={(scopes) => this.getAccessToken(scopes)}
        setError={(message, debug) => this.setErrorMessage(message, debug)}
        {...this.props} />;
    }

    async login() {
      try {
        // Login via popup
        await this.publicClientApplication.loginPopup(
          {
            scopes: config.scopes,
            prompt: "select_account"
          });

        // After login, get the user's profile
        await this.getUserProfile();
      }
      catch (err) {
        this.setState({
          isAuthenticated: false,
          user: {},
          error: this.normalizeError(err)
        });
      }
    }

    logout() {
      this.publicClientApplication.logout();
    }

    async getAccessToken(scopes) {
      try {
        const accounts = this.publicClientApplication
          .getAllAccounts();

        if (accounts.length <= 0) throw new Error('login_required');
        // Get the access token silently
        // If the cache contains a non-expired token, this function
        // will just return the cached token. Otherwise, it will
        // make a request to the Azure OAuth endpoint to get a token
        var silentResult = await this.publicClientApplication
          .acquireTokenSilent({
            scopes: scopes,
            account: accounts[0]
          });

        return silentResult.accessToken;
      } catch (err) {
        // If a silent request fails, it may be because the user needs
        // to login or grant consent to one or more of the requested scopes
        if (this.isInteractionRequired(err)) {
          var interactiveResult = await this.publicClientApplication
            .acquireTokenPopup({
              scopes: scopes
            });

          return interactiveResult.accessToken;
        } else {
          throw err;
        }
      }
    }

    async getUserProfile() {
      try {
        var accessToken = await this.getAccessToken(config.scopes);

        if (accessToken) {
          // Get the user's profile from Graph
          var user = await getUserDetails(accessToken);
          this.setState({
            isAuthenticated: true,
            user: {
              displayName: user.displayName,
              email: user.mail || user.userPrincipalName,
            },
            error: null
          });
        }
      }
      catch (err) {
        this.setState({
          isAuthenticated: false,
          user: {},
          error: this.normalizeError(err)
        });
      }
    }

    setErrorMessage(message, debug) {
      this.setState({
        error: { message: message, debug: debug }
      });
    }

    normalizeError(error) {
      var normalizedError = {};
      if (typeof (error) === 'string') {
        var errParts = error.split('|');
        normalizedError = errParts.length > 1 ?
          { message: errParts[1], debug: errParts[0] } :
          { message: error };
      } else {
        normalizedError = {
          message: error.message,
          debug: JSON.stringify(error)
        };
      }
      return normalizedError;
    }

    isInteractionRequired(error) {
      if (!error.message || error.message.length <= 0) {
        return false;
      }

      return (
        error.message.indexOf('consent_required') > -1 ||
        error.message.indexOf('interaction_required') > -1 ||
        error.message.indexOf('login_required') > -1 ||
        error.message.indexOf('no_account_in_silent_request') > -1
      );
    }
  }
}
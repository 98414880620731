import React, { useState, useEffect, useRef } from "react";
import { attendeeDataSchema } from './schemas';

const attendeeObjSchema = { prop: 'record', type: attendeeDataSchema };

export default function useAttendeesData() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const [byId, setById] = useState(null);
  const [obj, setObj] = useState(null);
  const objSchemaRef = useRef(null);

  useEffect(() => {
    if(obj) {
      (async () => {
        try {
          setLoading(true);
          if (obj && obj.text) {
            const rows = obj.text.slice(1);
            const headers = obj.text[0].reduce((accumulator, current, idx) => ({
              ...accumulator,
              [current]: idx
            }), {});
            objSchemaRef.current = headers;
            const schoolCodeIdx = headers["Código de Escuela"];
            //const observationIdx = headers["Observación"];
            //const teamSacpeIdx = headers["Equipo SACPE"];
            const reduced = rows
              //.filter((row) => !(row[observationIdx].toString().toLowerCase().split(' ').includes('baja')) && row[teamSacpeIdx].trim().toLowerCase() === "x")
              .reduce((accumulator, current) => {
                return {
                  ...accumulator,
                  [current[schoolCodeIdx]]: [
                    ...(accumulator[current[schoolCodeIdx]] || []),
                    current
                  ]
                }
              }, {});
            setData(rows);
            setById(reduced);
          }
        } catch(e) {
          setError(e);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [obj]);

  const parseProperty = (key, value, getRaw = (key) => key) => {
    const { prop, type } = value;
    if (typeof type === "function") {
      return {
        prop,
        value: type(getRaw(key))
      };
    } else if (typeof type === "object") {
      const entries = Object.entries(type);
      let accumulator = {};
      for (let index = 0; index < entries.length; index++) {
        const element = entries[index];
        const parsed = parseProperty(element[0], element[1], getRaw);
        accumulator = {
          ...accumulator,
          [parsed.prop]: parsed.value
        }
      }
      return {
        prop,
        value: accumulator
      }
    } else {
      return {
        prop
      }
    }
  };

  const objToRecord = (source) => {
    let result = [];
    if (source) {
      for (let index = 0; index < source.length; index++) {
        const element = source[index];
        const { value } = parseProperty('Record', attendeeObjSchema, (key) => element[objSchemaRef.current[key]]);
        result.push(value);
      } 
    }
    return result;
  };

  return {
    loading,
    error,
    data,
    byId,
    setObj,
    objToRecord
  };
}

import { parseStr, parseTime, dateParser, emailParser } from './helperFx';

export const calEdDataSchema = {
  'Validación': {
    prop: 'serviceNum',
    type: parseStr
  },
  'Código de Servicio': {
    prop: 'serviceCode',
    type: parseStr
  },
  'Código de Servicio Adic. (si aplica)': {
    prop: 'additionalServiceCode',
    type: parseStr
  },
  'Fecha Final Servicio': {
    prop: 'serviceFinalDate',
    type: (value) => {
      try {
        return dateParser(value);
      } catch (e) {
        console.log(e);
        return null;
      }
    }
  },
  'Hora Inicio': {
    prop: 'startTime',
    type: (value) => {
      try {
        return parseTime(value);
      } catch (e) {
        console.log(e);
        return null;
      }
    }
  },
  'Hora Salida': {
    prop: 'endTime',
    type: (value) => {
      try {
        return parseTime(value);
      } catch {
        return null;
      }
    }
  },
  'Fecha Cont. Servicio': {
    prop: 'serviceContDate',
    type: (value) => {
      try {
        return dateParser(value);
      } catch (e) {
        console.log(e);
        return null;
      }
    }
  },
  'Hora Inicio2': {
    prop: 'serviceContStartTime',
    type: (value) => {
      try {
        return parseTime(value);
      } catch (e) {
        console.log(e);
        return null;
      }
    }
  },
  'Hora Salida3': {
    prop: 'serviceContEndTime',
    type: (value) => {
      try {
        return parseTime(value);
      } catch {
        return null;
      }
    }
  },
  'Fecha Inicio Servicio': {
    prop: 'serviceStartDate',
    type: (value) => {
      try {
        return dateParser(value);
      } catch (e) {
        console.log(e);
        return null;
      }
    }
  },
  'Hora Inicio4': {
    prop: 'serviceStartStartTime',
    type: (value) => {
      try {
        return parseTime(value);
      } catch (e) {
        console.log(e);
        return null;
      }
    }
  },
  'Hora Salida5': {
    prop: 'serviceStartEndTime',
    type: (value) => {
      try {
        return parseTime(value);
      } catch {
        return null;
      }
    }
  },
  'Horas Servicio': {
    prop: 'serviceHours',
    type: parseStr
  },
  '# Part.': {
    prop: 'numberOfParticipants',
    type: parseStr
  },
  '# Part. Adic.': {
    prop: 'numberOfParticipantsAdic',
    type: parseStr
  },
  '# Part. Adic. No Charge': {
    prop: 'numberOfParticipantsAdicNoCharge',
    type: parseStr
  },
  'Total Part.': {
    prop: 'totalOfParticipants',
    type: parseStr
  },
  'Link Agenda': {
    prop: 'schedule',
    type: parseStr
  },
  'Link Manual': {
    prop: 'handbook',
    type: parseStr
  },
  'Link Listado Part.': {
    prop: 'listOfParticipants',
    type: parseStr
  },
  'Part. Minimo': { 
    prop: 'minimumParticipants',
    type: parseStr
  },
  'Link Contenido': {
    prop: 'transferLink',
    type: parseStr
  },
  'School': {
    prop: 'school',
    type: {
      'Código Escuela o # Lic.': {
        prop: 'code',
        type: parseStr
      },
      'Escuela': {
        prop: 'name',
        type: parseStr
      },
      'Tipo Escuela': {
        prop: 'type',
        type: parseStr
      },
      'Región': {
        prop: 'region',
        type: parseStr
      },
      'Principal': {
        prop: 'principal',
        type: {
          'Director Escuela': {
            prop: 'name',
            type: parseStr
          },
          'Tel. Móvil Director': {
            prop: 'phone',
            type: parseStr
          },
          'Email Escuela': {
            prop: 'email',
            type: emailParser
          }
        }
      },
      'Additional Contact': {
        prop: 'additionalContact',
        type: {
          'Persona Contacto Adicional': {
            prop: 'name',
            type: parseStr
          },
          'Tel. Móvil Adicional': {
            prop: 'phone',
            type: parseStr
          },
          'Email Adicional': {
            prop: 'email',
            type: emailParser
          }
        }
      }
    }
  },
  'Topic': {
    prop: 'topic',
    type: {
      'Código Tema': {
        prop: 'code',
        type: parseStr
      },
      'Tema': {
        prop: 'name',
        type: parseStr
      },
      'Tipo Part.': {
        prop: 'type',
        type: parseStr
      },
      'Modalidad': {
        prop: 'modality',
        type: parseStr
      },
    }
  },
  'Resource': {
    prop: 'resource',
    type: {
      'Recurso': {
        prop: 'name',
        type: parseStr
      },
      'Teléfono Recurso': {
        prop: 'phone',
        type: parseStr
      },
      'Email Recurso': {
        prop: 'email',
        type: emailParser
      }
    }
  },
  'Assistant': {
    prop: 'assistant',
    type: {
      'Asistente (si aplica)': {
        prop: 'name',
        type: parseStr,
        required: false
      },
      'Teléfono Asistente': {
        prop: 'phone',
        type: parseStr,
        required: false
      },
      'Email Asistente': {
        prop: 'email',
        type: emailParser,
        required: false
      }
    }
  },
  'Virtual Meeting': {
    prop: 'virtualMeet',
    type: {
      'Título de la reunión': {
        prop: 'title',
        type: parseStr
      },
      'Lugar de Servicio': {
        prop: 'platform',
        type: parseStr
      },
      'Cuenta Zoom': {
        prop: 'username',
        type: parseStr
      },
      'Password Zoom': {
        prop: 'password',
        type: parseStr
      },
      'URL Registro': {
        prop: 'url',
        type: parseStr
      }
    }
  },
  'Ejecutivo': {
    prop: 'executive',
    type: {
      'Representante A+': {
        prop: 'name',
        type: parseStr
      },
      'Teléfono Rep. A+': {
        prop: 'phone',
        type: parseStr
      },
      'Email Rep. A+': {
        prop: 'email',
        type: emailParser
      }
    }
  },
  'Plan de Trabajo': {
    prop: 'projectPlan',
    type: parseStr
  },
  'Título de la reunión': {
    prop: 'subject',
    type: parseStr
  },
  'Contacto A+': {
    prop: 'contact',
    type: {
      'Monitor': {
        prop: 'name',
        type: parseStr
      },
      'Teléfono Monitor': {
        prop: 'phone',
        type: parseStr
      },
      'Email Monitor': {
        prop: 'email',
        type: emailParser
      }
    }
  },
  'Instrucciones 1': {
    prop: 'instructions_1',
    type: parseStr
  },
  'Instrucciones 2': {
    prop: 'instructions_2',
    type: parseStr
  }
};

export const sacpeDataSchema = {
  'Código de Escuela': {
    prop: 'schoolCode',
    type: parseStr
  },
  'Nombre del Participante': {
    prop: 'name',
    type: parseStr
  },
  'Tipo de Puesto de Participante': {
    prop: 'role',
    type: parseStr
  },
  'Número de Teléfono Móvil o Teléfono contacto:': {
    prop: 'phone',
    type: parseStr
  },
  'Correo electrónico DE:': {
    prop: 'email',
    type: emailParser
  },
  'Correo electrónico personal:': {
    prop: 'altEmail',
    type: parseStr
  },
  'Observación': {
    prop: 'observation',
    type: parseStr
  },
  'Equipo SACPE': {
    prop: 'teamSACPE',
    type: parseStr
  }
};

export const ssipDataSchema = {
  'Nombre Participante': {
    prop: 'name',
    type: parseStr
  },
  'Número de Teléfono Móvil o Teléfono contacto:': {
    prop: 'phone',
    type: parseStr
  },
  'Correo electrónico:': {
    prop: 'email',
    type: emailParser
  },
  'Aprobados': {
    prop: 'approved',
    type: parseStr
  },
};

export const attendeeDataSchema = {
  'Código de Escuela': {
    prop: 'schoolCode',
    type: parseStr
  },
  'Nombre del Participante': {
    prop: 'name',
    type: parseStr
  },
  'Tipo de Puesto de Participante': {
    prop: 'role',
    type: parseStr
  },
  'Número de Teléfono Móvil o Teléfono contacto': {
    prop: 'phone',
    type: parseStr
  },
  'Correo electrónico DE': {
    prop: 'email',
    type: emailParser
  },
  'Correo electrónico personal': {
    prop: 'altEmail',
    type: parseStr
  },
  'Observación': {
    prop: 'observation',
    type: parseStr
  },
  'Equipo SACPE': {
    prop: 'teamSACPE',
    type: parseStr
  },
  'Proyecto': {
    prop: 'projectPlan',
    type: parseStr
  }
};
import * as React from 'react';
import { createElement } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import { DashboardMenuItem, MenuItemLink, getResources, useTranslate } from 'react-admin';
import DefaultIcon from '@material-ui/icons/ViewList';
import StorageIcon from '@material-ui/icons/Storage';
import Box from '@material-ui/core/Box';

const Menu = ({ onMenuClick, logout }) => {
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const open = useSelector(state => state.admin.ui.sidebarOpen);
  const resources = useSelector(getResources);
  const translate = useTranslate();
  return (
    <Box pt={3}>
      {/*<DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} />*/}
      {resources.filter(({ name }) => !(["cognitoGroups"].some(excluded => excluded === name))).map(resource => (
        <MenuItemLink
          key={resource.name}
          to={`/${resource.name}`}
          primaryText={
            (resource.options && resource.options.label) ||
            resource.name
          }
          leftIcon={
            resource.icon ? <resource.icon /> : <DefaultIcon />
          }
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      ))}
      <MenuItemLink
        to="/onedrive-setup"
        primaryText="OneDrive"
        leftIcon={<StorageIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      {isXSmall && logout}
    </Box>
  );
};

export default Menu;
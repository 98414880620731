import { defaultTheme } from 'react-admin';
import merge from 'lodash/merge';
import teal from '@material-ui/core/colors/teal';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';

export const theme = createMuiTheme(merge(
  {},
  defaultTheme,
  {
    palette: {
      primary: {
        main: teal[400]
      },
      secondary: {
        main: '#073b4c'
      }
    },
    typography: {
      // Use the system font instead of the default Roboto font.
      //fontFamily: ['-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Arial', 'sans-serif'].join(','),
    }
  }
));
import * as React from 'react';
import { AppBar as RaAppBar } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

//import { ReactComponent as Logo } from '../../assets/aplus-logo.svg';
import logo from '../../assets/aplus-logo-only.png';

const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
});

const AppBar = props => {
    const classes = useStyles();
    return (
        <RaAppBar {...props}>
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />
            <img src={logo} width={35} />
            <span className={classes.spacer} />
        </RaAppBar>
    );
};

export default AppBar;
import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceArrayInput,
  SelectArrayInput,
  ArrayInput,
  SimpleFormIterator,
  ReferenceInput,
  SelectInput,
  required,
  regex,
  maxLength
} from "react-admin";
import _ from 'lodash';
import Editor from './Editor';

const nameErrorMsg = 'Debe empezar con un caracter alphanumérico y puede contener un máximo de 128 caracteres. Los caracteres pueden ser alphanuméricos, underscores (_) o hyphens (-).'
const validateName = [required(), maxLength(128, nameErrorMsg), regex(/^[a-zA-Z0-9]([a-zA-Z0-9_])+$/, nameErrorMsg)];

//(?<={{).*?(?=}})
const TemplateCreate = (props) => {
  return (
    <Create {...props} transform={(data) => {
      return {
        ...data,
        emailTemplate: {
          templateName: data.name,
          templateDescription: data.description,
          ...data.emailTemplate
        }
      };
    }}>
      <SimpleForm redirect="list">
        <TextInput
          source="name"
          validate={validateName}
          parse={(value) => value ? value.replace(" ", "_") : value}
        />
        <TextInput
          source="description"
          multiline
          resettable
        />
        <ArrayInput
          source="attachments"
          label="Attachments"
        >
          <SimpleFormIterator>
            <ReferenceInput
              source="fileId"
              reference="File"
              label="" 
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
        <Editor
          label="Contenido"
        />
      </SimpleForm>
    </Create>
  );
};

export default TemplateCreate;
